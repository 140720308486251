var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "msgInput" }, [
    _c(
      "div",
      { staticClass: "sendInput" },
      [
        _vm.fileList.length
          ? _c(
              "div",
              { staticClass: "fileList" },
              _vm._l(_vm.fileList, function (item) {
                return _c(
                  "div",
                  { key: item.uid, staticClass: "fileItem" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: item.name,
                          placement: "bottom",
                        },
                      },
                      [
                        _vm.isImage(item)
                          ? _c("img", {
                              attrs: { src: item.previewUrl, alt: "" },
                            })
                          : _vm.isVideo(item)
                          ? _c("video", { attrs: { src: item.previewUrl } })
                          : _c("i", {
                              staticClass: "iconfont icon-wenanmoban",
                            }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "closeBox",
                        on: {
                          click: function ($event) {
                            return _vm.closeFile(item)
                          },
                        },
                      },
                      [_vm._v("x")]
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _c("el-input", {
          staticStyle: { width: "95%" },
          attrs: {
            id: "postContent",
            maxlength: "200",
            autosize: { minRows: 2, maxRows: 3 },
            placeholder: _vm.$t("messageCenter.placeholder") + _vm.$t("enter"),
            type: "textarea",
          },
          nativeOn: {
            keydown: function ($event) {
              return _vm.handleKeyDown($event)
            },
          },
          model: {
            value: _vm.message,
            callback: function ($$v) {
              _vm.message = $$v
            },
            expression: "message",
          },
        }),
        _c("div", { staticClass: "sendBtn" }, [
          _c("i", {
            staticClass: "iconfont icon-a-danyuanpeizhi2",
            on: {
              click: function ($event) {
                _vm.showEmoPicker = !_vm.showEmoPicker
              },
            },
          }),
          _c(
            "div",
            { staticClass: "sendMessage", on: { click: _vm.sendMessage } },
            [_c("i", { staticClass: "iconfont icon-telegram-normal" })]
          ),
        ]),
        _c("msgEmopicker", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmoPicker,
              expression: "showEmoPicker",
            },
          ],
          staticClass: "emopiker",
          attrs: { inputId: "postContent", showFrequentlyUsedList: false },
          on: { select: _vm.emojiClick, selectBack: _vm.updateContent },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }