<!-- 订单中心-我的买单 -->
<template>
  <div class="app-container">
    <div class="flex-row-center f-between w-100 mb10">
      <div class="f-shrink">
        <el-button
          class="button-white"
          icon="iconfont icon-delete"
          @click="handleDelete"
          :disabled="multiple"
        >{{ $t('remove') }}</el-button>
      </div>
      <div class="flex-row-center">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <i
            class="iconfont icon-a-danyuanpeizhi6"
            @click="getList"
          ></i>
        </el-tooltip>
        <div style="width: 400px;margin:0 5px;">
          <el-input
            class="account-input"
            v-model="queryParams.orderNo"
            :placeholder="$t('mall.enterOrderNumber')"
            @keyup.enter.native="handleQuery"
            clearable
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-sousuotubiao"
              @click="handleQuery"
            ></i>
          </el-input>
        </div>
      </div>
    </div>
    <el-table
      header-row-class-name="custom_table_header"
      height="calc(100vh - 270px)"
      v-loading="loading"
      :data="mallOrderList"
      @filter-change="filterChange"
      @selection-change="handleSelectionChange"
    >
      <el-empty
        :image-size="180"
        slot="empty"
      ></el-empty>
      <el-table-column
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        :label="$t('mall.orderNumber')"
        align="center"
        prop="orderNo"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.mallOrder.orderNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('mall.productInfo')"
        align="center"
        prop="title"
        width="150"
      >
        <template slot-scope="scope">
          <div class="flex-row-center">
            <div
              class="flex-center"
              style="width: 50px;height: 50px;"
            >
              <img
                :src="scope.row.coverUrl"
                style="max-width: 50px; max-height: 50px"
              />
            </div>
            <span class="ml5">{{ scope.row.title }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('mall.sellerInformation')"
        align="center"
        prop="sellNickName"
      />
      <el-table-column
        :label="$t('mall.price')"
        align="center"
        prop="price"
      />
      <el-table-column
        :label="$t('mall.quantity')"
        align="center"
        prop="buyCount"
      >
      </el-table-column>
      <el-table-column
        :label="$t('mall.paidAmount')"
        align="center"
        prop="payPrice"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.mallOrder.payPrice || scope.row.price * (scope.row.buyCount || 1)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        column-key="orderStatus"
        prop="orderStatus"
        align="center"
        :label="$t('mall.orderStatus')"
        :filters="orderStatusFilters"
        :filter-multiple="false"
      >
        <template slot-scope="scope">
          <dict-tag
            :options="dict.type.order_status"
            :value="scope.row.mallOrder.orderStatus"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('mall.paymentMethod')"
        align="center"
        prop="payType"
      >
        <template>
          <span>{{ $t('mall.wechat') }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="修改时间" align="center" prop="udpateTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.udpateTime, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        v-loading="operateLoading"
        :label="$t('operate')"
        align="center"
        width="250"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="cancelOrder(scope.row)"
            v-if="scope.row.mallOrder.orderStatus == 0"
          >{{ $t('mall.cancelOrder') }}</el-button>
          <el-button
            size="mini"
            type="text"
            @click="payOrder(scope.row)"
            v-if="scope.row.mallOrder.orderStatus == 0"
          >{{ $t('mall.goToPay') }}</el-button>
          <el-button
            size="mini"
            type="text"
            @click="goDetail(scope.row)"
          >{{ $t('detail') }}</el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.row)"
          >{{ $t('remove') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
    <PayDialog
      ref="payDialog"
      :visible.sync="payVisible"
      :orderInfo="orderInfo"
    />
  </div>
</template>

<script>
import { listMallOrder, delMallOrder } from "@/api/mall/mallOrder";
import { cancelMallOrder, payMallOrder } from "../../api/mall/mallOrder";
import PayDialog from "./PayDialog.vue";
export default {
  // name: "OrderCenter",
  components: {
    PayDialog,
  },
  dicts: ["order_status"],
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 订单表格数据
      mallOrderList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        payType: undefined,
        orderNo: undefined,
        price: undefined,
        payPrice: undefined,
        orderStatus: undefined,
        udpateTime: undefined,
        expireTime: undefined,
      },
      operateLoading: false, //操作的loading
      payVisible: false, //支付弹窗
      orderInfo: null, //支付订单数据
    };
  },
  computed: {
    orderStatusFilters() {
      return this.dict.type.order_status.map((type) => ({
        text: type.label,
        value: type.value,
      }));
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 筛选
    filterChange(filter) {
      if (filter["orderStatus"]) {
        // 修改传给后端接口的参数，并重新调用接口
        this.queryParams.orderStatus = filter.orderStatus.join(",");
        this.handleQuery();
      }
    },
    /** 查询订单列表 */
    getList() {
      this.loading = true;
      listMallOrder(this.queryParams).then((response) => {
        this.mallOrderList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.mallOrder.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.mallOrder.id || this.ids;
      this.$modal
        .confirm(this.$t("mall.confirmDeleteOrder"))
        .then(() => {
          this.loading = true;
          return delMallOrder(ids);
        })
        .then(() => {
          this.loading = false;
          this.getList();
          this.$modal.msgSuccess(this.$t("tip.remove"));
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "system/mallOrder/export",
        {
          ...this.queryParams,
        },
        `mallOrder_${new Date().getTime()}.xlsx`
      );
    },
    //取消订单
    cancelOrder(row) {
      this.operateLoading = true;
      cancelMallOrder(row.mallOrder.id)
        .then((res) => {
          this.$modal.msgSuccess(this.$t("mall.cancelSuccess"));
          this.getList();
        })
        .finally(() => {
          this.operateLoading = false;
        });
    },
    //去付款
    payOrder(row) {
      this.operateLoading = true;
      payMallOrder(row.mallOrder.id)
        .then((res) => {
          this.orderInfo = res.data;
          this.payVisible = true;
          this.$nextTick().then((rs) => {
            this.$refs.payDialog.init();
          });
          // this.orderInfo = {
          //   mallOrderId: "1830895760723333122",
          //   qrCode: "weixin://wxpay/bizpayurl?pr=naYOfCHz3",
          //   time: 1725354540
          // }
        })
        .finally(() => {
          this.operateLoading = false;
        });
    },
    goDetail(row) {
      this.$router.push({
        path: `/orderDetail/${row.mallOrder.id}`,
      });
    },
  },
};
</script>
