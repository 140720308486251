<!-- 购物车 -->
<template>
  <div class="app-container">
    <div class="flex-row-center f-between w-100 mb10">
      <div class="f-shrink">
        <el-button
          class="gradient-button"
          @click="checkOrder"
          :disabled="multiple"
        >{{ $t('mall.checkout') }}</el-button>
        <el-button
          class="button-white"
          icon="iconfont icon-delete"
          @click="handleDelete"
          :disabled="multiple"
        >{{ $t('remove') }}</el-button>
      </div>
      <div class="flex-row-center">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <i
            class="iconfont icon-a-danyuanpeizhi6"
            @click="getList"
          ></i>
        </el-tooltip>
        <div style="width: 400px;margin:0 5px;">
          <el-input
            class="account-input"
            v-model="queryParams.searchValue"
            :placeholder="$t('mall.searchNameOrDesc')"
            @keyup.enter.native="handleQuery"
            clearable
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-sousuotubiao"
              @click="handleQuery"
            ></i>
          </el-input>
        </div>
      </div>
    </div>
    <el-table
      header-row-class-name="custom_table_header"
      height="calc(100vh - 270px)"
      v-loading="loading"
      :data="mallCartItemsList"
      @selection-change="handleSelectionChange"
    >
      <el-empty
        :image-size="180"
        slot="empty"
      ></el-empty>
      <el-table-column
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        :label="$t('mall.productName')"
        align="center"
        prop="title"
      />
      <el-table-column
        :label="$t('mall.sellerInformation')"
        align="center"
        prop="sellNickName"
      />
      <el-table-column
        :label="$t('mall.productDescription')"
        align="center"
        prop="description"
      />
      <el-table-column
        :label="$t('mall.unitPrice')"
        align="center"
        prop="price"
      />
      <el-table-column
        :label="$t('mall.quantity')"
        align="center"
        prop="count"
        v-loading="changeLoading"
      >
        <template slot-scope="scope">
          <el-input-number
            v-model="scope.row.count"
            :min="1"
            :max="50"
            :label="$t('mall.quantity')"
            @change="handleCountChange(scope.row)"
            style="width: 120px;"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operate')"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="iconfont icon-delete"
            @click="handleDelete(scope.row)"
          >{{ $t('remove') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
    <EmailDialog
      :visible.sync="emailVisible"
      @confirm="createOrder"
    />
    <PayDialog
      ref="payDialog"
      :visible.sync="payVisible"
      :orderInfo="orderInfo"
      @refresh="getList"
      @paySuccess="paySuccess"
    />
  </div>
</template>

<script>
import {
  listMallCart,
  delMallCart,
  updateMallCart,
} from "@/api/mall/mallCartItems";
import { createMallOrder } from "../../api/mall/mallOrder";
import EmailDialog from "./EmailDialog.vue";
import PayDialog from "./PayDialog.vue";
export default {
  // name: "ShoppingCart",
  components: {
    EmailDialog,
    PayDialog,
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 购物车表格数据
      mallCartItemsList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        mallGoodsId: undefined,
        quantity: undefined,
      },
      changeLoading: false, //修改数量的loading
      emailVisible: false, //邮箱弹窗
      payVisible: false, //支付弹窗
      orderInfo: null, //支付订单数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询购物车列表 */
    getList() {
      this.loading = true;
      listMallCart(this.queryParams).then((response) => {
        this.mallCartItemsList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    handleCountChange(row) {
      this.changeLoading = true;
      updateMallCart({
        id: row.id,
        mallGoodsId: row.mallGoodsId,
        count: row.count,
      })
        .then((response) => {})
        .finally(() => {
          this.changeLoading = false;
          this.getList();
        });
    },
    //结算前检查是否有tiktok商品
    checkOrder() {
      const hasTiktokType = this.mallCartItemsList
        .filter((item) => this.ids.includes(item.id))
        .some((item) => item.goodsType === "account");
      if (hasTiktokType) {
        this.emailVisible = true;
      } else {
        this.createOrder();
      }
    },
    //结算
    createOrder(value) {
      if (!this.ids || this.ids.length === 0) {
        this.$modal.msgError(this.$t("mall.selectProduct"));
        return;
      }
      const result = this.mallCartItemsList
        .filter((item) => this.ids.includes(item.id)) // 筛选出id匹配的项
        .map((item) => ({
          // 生成新的对象数组
          id: item.id,
          mallGoodsId: item.mallGoodsId,
          count: item.count,
        }));
      // const total = this.mallCartItemsList
      //   .filter(item => this.ids.includes(item.id)) // 筛选出 id 匹配的项
      //   .reduce((sum, item) => sum + parseFloat(item.price), 0);
      // this.price = total
      this.loadingBuy = true;
      createMallOrder({
        receiveEmail: (value && value.email) || null,
        createOrderGoodsList: result,
      })
        .then((res) => {
          this.orderInfo = res.data;
          this.payVisible = true;
          this.$nextTick().then((rs) => {
            this.$refs.payDialog.init();
          });
        })
        .finally(() => {
          this.loadingBuy = false;
        });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal
        .confirm(this.$t("mall.confirmDeleteProduct"))
        .then(() => {
          this.loading = true;
          return delMallCart(ids);
        })
        .then(() => {
          this.loading = false;
          this.getList();
          this.$modal.msgSuccess(this.$t("tip.remove"));
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "system/mallCartItems/export",
        {
          ...this.queryParams,
        },
        `mallCartItems_${new Date().getTime()}.xlsx`
      );
    },
    paySuccess() {
      this.$tab.closeOpenPage({ path: "/mall/orderCenter" });
    },
  },
};
</script>
