<!-- tiktok账号专区 -->
<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      v-show="showSearch"
    >
      <el-form-item prop="platform">
        <el-select
          v-model="queryParams.platform"
          :placeholder="$t('mall.accountType')"
          clearable
          @change="handleQuery"
          style="width: 240px"
        >
          <el-option
            v-for="dict in dict.type.social_platform"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="businessType">
        <el-cascader
          :placeholder="$t('mall.businessDirection')"
          style="width: 100%;"
          :show-all-levels="false"
          :props="{value:'name',label:'name',emitPath: false}"
          v-model="queryParams.businessType"
          :options="industryCategoryOptions"
          @change="handleQuery"
          clearable
        ></el-cascader>
      </el-form-item>
      <!-- <el-form-item prop="country">
        <el-select
          v-model="queryParams.languageId"
          :placeholder="$t('mall.accountCountry')"
          @change="getList"
          clearable
          filterable
        >
          <el-option
            v-for="language in languageOptions "
            :key="language.id"
            :label="language.countryName"
            :value="language.countryName"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <div class="flex-row-center price-box">
          <el-input
            v-model="queryParams.priceMin"
            :placeholder="$t('mall.priceMin')"
            clearable
            @change="handleQuery"
            style="width: 100px;"
          />
          <!-- @keyup.enter.native="handleQuery" -->
          <span class="ml8 mr8">-</span>
          <el-input
            v-model="queryParams.priceMax"
            :placeholder="$t('mall.priceMax')"
            clearable
            @change="handleQuery"
            style="width: 100px;"
          />
        </div>
      </el-form-item>
      <el-form-item>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <i
            class="iconfont icon-a-danyuanpeizhi6"
            @click="getList"
          ></i>
        </el-tooltip>
      </el-form-item>
    </el-form>
    <el-empty
      v-if="mallTiktokList.length === 0"
      v-loading="loading"
      :image-size="200"
    ></el-empty>
    <div
      v-else
      class="flex-row f-wrap"
      v-loading="loading"
    >
      <tiktok-item
        v-for="(item,index) in mallTiktokList"
        :key="item.id"
        :item="item"
        @toggle-selection="toggleSelectionSingle"
        style="margin: 0px 10px 10px 0px;"
        @add-cart-success="addCardSuccess(index)"
      ></tiktok-item>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import TiktokItem from "./TiktokItem.vue";
import { listMallTiktok } from "@/api/mall/mallTiktok";
import { listLanguage } from "../../api/ai/language";
import { industryCategoryTreeSelect } from "../../api/base/resource";

export default {
  name: "MallTiktok",
  components: { TiktokItem },
  dicts: ["social_platform"],
  data() {
    return {
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // tiktok账号商品表格数据
      mallTiktokList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        platform: undefined,
        businessType: undefined,
        // country: undefined,
        priceMin: undefined,
        priceMax: undefined,
        priceRange: [],
      },
      languageOptions: undefined, //国家
      industryCategoryOptions: undefined, // 业务方向、行业分类
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 所属行业下拉树结构 */
    getIndustryCategoryOptionsTree() {
      industryCategoryTreeSelect().then((response) => {
        this.industryCategoryOptions = response.data;
      });
    },
    /** 查询tiktok账号商品列表 */
    getList() {
      if (
        !this.industryCategoryOptions ||
        this.industryCategoryOptions.length === 0
      ) {
        this.getIndustryCategoryOptionsTree();
      }
      if (!this.languageOptions || this.languageOptions.length === 0) {
        this.getLanguage();
      }
      this.loading = true;
      if (this.queryParams.priceMax) {
        this.queryParams.priceRange = [
          this.queryParams.priceMin || 0,
          this.queryParams.priceMax,
        ];
      } else {
        this.queryParams.priceRange = [this.queryParams.priceMin || 0];
      }
      listMallTiktok(this.queryParams).then((response) => {
        this.mallTiktokList = response.rows;
        this.total = response.total;
        this.loading = false;
        this.mallTiktokList.forEach((item) => {
          item.selected = false;
        });
      });
    },
    //选中
    toggleSelectionSingle(selected, video) {
      console.log("selected", selected);
      // 取消所有其他项目的选择
      this.mallTiktokList = this.mallTiktokList.map((v) => ({
        ...v,
        selected: false,
      }));

      // 选择当前点击的项目
      const index = this.mallTiktokList.findIndex((v) => v.id === video.id);
      if (index !== -1) {
        this.$set(this.mallTiktokList, index, {
          ...this.mallTiktokList[index],
          selected,
        });
      }
      console.log("this.mallTiktokList", this.mallTiktokList);
      this.$router.push({ path: `/tiktokDetail/${video.id}` });
    },
    addCardSuccess(index) {
      this.$set(this.mallTiktokList, index, {
        ...this.mallTiktokList[index],
        inCart: true,
      });
    },
    getLanguage() {
      listLanguage().then((response) => {
        this.languageOptions = response.data;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 20,
        platform: undefined,
        businessType: undefined,
        // country: undefined,
        priceMin: undefined,
        priceMax: undefined,
        priceRange: [],
      };
      this.resetForm("queryForm");
      this.handleQuery();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner {
  border: none;
  background: #f4f4f5;
  border-radius: 8px;
}
.price-box {
  border: none;
  background: #f4f4f5;
  border-radius: 8px;
}
</style>
