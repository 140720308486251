var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "msgHeader" }, [
    _c("div", { staticClass: "msgHeaderTitle" }, [
      _vm._v(" " + _vm._s(_vm.userName) + " "),
    ]),
    _c("div", { staticClass: "refreshMsg", on: { click: _vm.refreshMsg } }, [
      _c("i", { staticClass: "iconfont icon-a-danyuanpeizhi6" }),
      _vm._v(" " + _vm._s(_vm.$t("messageCenter.refreshMessage")) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }