<!-- 买家账单 -->
<template>
  <div class="app-container">
    <div class="flex-row-center f-end w-100 mb10">
      <div class="flex-row-center">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <i
            class="iconfont icon-a-danyuanpeizhi6"
            @click="getList"
          ></i>
        </el-tooltip>
        <div style="width: 400px;margin:0 5px;">
          <el-input
            class="account-input"
            v-model="queryParams.billNo"
            :placeholder="$t('mall.enterBillNumber')"
            @keyup.enter.native="handleQuery"
            clearable
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-sousuotubiao"
              @click="handleQuery"
            ></i>
          </el-input>
        </div>
      </div>
    </div>
    <el-table
      header-row-class-name="custom_table_header"
      height="calc(100vh - 270px)"
      v-loading="loading"
      :data="mallBillList"
      @selection-change="handleSelectionChange"
    >
      <el-empty
        :image-size="180"
        slot="empty"
      ></el-empty>
      <!-- <el-table-column type="selection" width="55" align="center" /> -->
      <el-table-column
        :label="$t('mall.billNumber')"
        align="center"
        prop="billNo"
      />
      <el-table-column
        :label="$t('mall.orderNumber')"
        align="center"
        prop="mallOrderNo"
      >
        <template slot-scope="scope">
          <span
            style="color: blue;cursor: pointer;"
            @click="toOrderDetail(scope.row)"
          >{{ scope.row.mallOrderNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('mall.incomeType')"
        align="center"
        prop="billType"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.billType == 'buy'">{{ $t('mall.expense') }}</span>
          <span v-else>{{ $t('mall.income') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('mall.amount')"
        align="center"
        prop="money"
      />
      <el-table-column
        :label="$t('mall.paymentMethod')"
        align="center"
        prop="payType"
      >
        <template>
          <span>{{ $t('mall.wechat') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('time.createTime')"
        align="center"
        prop="createTime"
      />
      <el-table-column
        :label="$t('mall.transactionStatus')"
        align="center"
        prop="status"
      >
        <template slot-scope="scope">
          {{scope.row.status == 1 ? $t('mall.failure') : $t('mall.success')}}
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { listMallBill } from "@/api/mall/mallBill";

export default {
  // name: "BillList",
  data() {
    return {
      // 按钮loading
      buttonLoading: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 【请填写功能名称】表格数据
      mallBillList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        merchantId: undefined,
        billType: undefined,
        userId: undefined,
        money: undefined,
        payType: undefined,
        mallOrderId: undefined,
        billNo: undefined,
        status: undefined,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询账单列表 */
    getList() {
      this.loading = true;
      listMallBill(this.queryParams).then((response) => {
        this.mallBillList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    toOrderDetail(row) {
      this.$router.push({
        path: `/orderDetail/${row.mallOrderId}`,
      });
    },
  },
};
</script>
