<template>
  <div class="flex-column video-item" :class="{ 'selected': item.selected }" @click.stop="toggleSelection">
    <div class="flex-center video-cover">
      <!-- <svg-icon icon-class="mall-tiktok" style="width: 130px;height: 130px;"/> -->
      <!-- <video :src="item.coverUrl" alt="video" class="video-cover" /> -->
      <img :src="item.coverUrl" alt="image" class="image-cover" />
    </div>
    <div>
      <div class="video-name">{{ item.title }}</div>
      <div class="video-description single-line-ellipsis">{{ item.description }}</div>
    </div>
    <div class="flex-row-center f-between">
      <div>¥{{ item.price }}</div>
      <div class="strikethrough">¥{{ item.originalPrice }}</div>
      <el-tooltip :content="$t('mall.addToCart')">
        <el-button :loading="loading" icon="el-icon-shopping-cart-full" circle @click.stop="addCart"></el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { addMallCart } from '../../api/mall/mallCartItems';
export default {
  name: 'ShoppingCartItem',
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      // selected: false,  // 添加选中状态
      loading: false,
    };
  },
  methods: {
    //加入购物车
    addCart(){
      this.loading = true
      addMallCart({
        mallGoodsId:this.item.id,
        count:1
      }).then(res=>{
        this.$modal.msgSuccess(this.$t('mall.addToCartSuccess'));
      }).finally(()=>{
        this.loading = false
      })
    },
    toggleSelection() {
      this.item.selected = !this.item.selected;
      console.log('this.item.selected',this.item.selected);
      this.$emit('toggle-selection', this.item.selected, this.item);
    }
  }
};
</script>

<style scoped>
.video-item {
  width: 200px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.video-item.selected {
  border-color: blue; /* 选中时的样式示例 */
}

.video-cover {
  width: 190px;
  height: 190px;
}
.image-cover {
  max-width: 100%; /* 限制最大宽度 */
  max-height: 100%; /* 限制最大高度 */
  width: auto; /* 自动调整宽度 */
  height: auto; /* 自动调整高度 */
}
.video-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.video-description{
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.strikethrough{
  text-decoration: line-through; /* 添加划线效果 */
  color: #999;                   /* 可选：调整颜色以区分原价 */
}
</style>
