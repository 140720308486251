<template>
  <el-dialog
    destroy-on-close
    class="publicDialog"
    :visible.sync="localVisible"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="70%"
    :show-close="false"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ $t('mall.pay') }}</div>
        <i
          class="el-icon-close"
          @click="close"
        ></i>
      </div>
    </div>
    <div style="text-align: center">
      <div style="color:#404E63">
        <span>{{ $t('mall.transactionNumber') }}：{{ orderInfo && orderInfo.outTradeNo || '--' }}</span>
      </div>
      <h2>{{ $t('mall.paymentAmount') }}:<span style="color:#C3923F">￥{{ orderInfo && orderInfo.totalMoneyAll || '--'}}</span></h2>
      <el-divider></el-divider>
      <div style="height:50%">
        <span>{{ $t('mall.wechatScan') }}</span>
        <span>{{ $t('mall.remainingTime') }}：{{ countDown }}</span><br />
        <vue-qr
          :logoSrc="imageUrl"
          :text="orderInfo && orderInfo.qrCode"
          :size="200"
        ></vue-qr>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import vueQr from "vue-qr";
import { getMallWxOrder } from "../../api/mall/mallWxOrder";
export default {
  name: "PayDialog",
  components: {
    vueQr,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    orderInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localVisible: this.visible,
      countDown: "",
      imageUrl: require("../../assets/images/wxpay.png"),
      timer: null,
      timer2: null,
    };
  },
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    },
  },
  created() {},
  methods: {
    init() {
      this.stopSetInterval();
      this.timer = setInterval(() => {
        this.countDown = this.calculateRemainingTime(this.orderInfo.expireTime);
        if (this.countDown === "00:00") {
          this.$modal.msgError(this.$t("mall.paymentOvertime"));
          this.toastClose();
        }
      }, 1000);
      this.createSetInterval();
    },
    createSetInterval() {
      let _this = this;
      this.timer2 = setInterval(() => {
        getMallWxOrder(_this.orderInfo.outTradeNo).then((res) => {
          if (res.data.tradeState == "SUCCESS") {
            this.$modal.msgSuccess(this.$t("mall.paymentSuccess"));
            this.toastClose(1);
          }
          // else if (res.data.orderStatus == 2) {
          //   this.$modal.msgError(this.$t('mall.paymentCancel'))
          //   this.toastClose()
          // } else if (res.data.orderStatus == 3) {
          //   this.$modal.msgError(this.$t('mall.paymentFail'))
          //   this.toastClose()
          // }
        });
      }, 5000);
    },
    toastClose(type) {
      this.stopSetInterval();
      setTimeout(() => {
        if (type == 1) {
          this.$emit("paySuccess", true);
        }
        this.close();
      }, 1000);
    },
    // 关闭轮询
    stopSetInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      if (this.timer2) {
        clearInterval(this.timer2);
        this.timer2 = null;
      }
    },
    handleBeforeClose(done) {
      this.stopSetInterval();
      this.close();
      done();
    },
    close() {
      this.localVisible = false;
      this.$emit("update:visible", false);
      this.$emit("refresh", true);
    },
  },
};
</script>
<style lang="scss" scoped></style>
