<!-- 商家-商品管理、我的商品 -->
<template>
  <div class="app-container">
    <div class="flex-row-center f-between w-100 mb10">
      <div class="f-shrink">
        <el-button
          class="gradient-button"
          icon="el-icon-plus"
          @click="handleAdd"
        >{{ $t('add') }}</el-button>
        <el-button
          class="button-white"
          icon="iconfont icon-delete"
          @click="handleDelete"
          :disabled="multiple"
        >{{ $t('remove') }}</el-button>
      </div>
      <div class="flex-row-center">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <i
            class="iconfont icon-a-danyuanpeizhi6"
            @click="getList"
          ></i>
        </el-tooltip>
        <div style="width: 400px;margin:0 5px;">
          <el-input
            class="account-input"
            v-model="queryParams.title"
            :placeholder="$t('mall.enterProductName')"
            @keyup.enter.native="handleQuery"
            clearable
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-sousuotubiao"
              @click="handleQuery"
            ></i>
          </el-input>
        </div>
      </div>
    </div>
    <!-- <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item prop="platform" v-if="queryParams.goodsType == 'account'">
        <el-select v-model="queryParams.platform" :placeholder="$t('mall.accountType')" clearable @change="handleQuery">
          <el-option v-for="dict in dict.type.social_platform" :key="dict.value" :label="dict.label"
            :value="dict.value" />
        </el-select>
      </el-form-item>
      <el-form-item prop="fileType" v-if="queryParams.goodsType == 'material'">
        <el-select v-model="queryParams.fileType" :placeholder="$t('mall.materialType')" clearable @change="handleQuery">
          <el-option v-for="dict in dict.type.file_type" :key="dict.value" :label="dict.label"
            :value="dict.value" />
        </el-select>
      </el-form-item>
    </el-form> -->
    <el-table
      header-row-class-name="custom_table_header"
      height="calc(100vh - 270px)"
      v-loading="loading"
      :data="mallGoodsList"
      @filter-change="filterChange"
      @selection-change="handleSelectionChange"
    >
      <el-empty
        :image-size="180"
        slot="empty"
      ></el-empty>
      <el-table-column
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        :label="$t('mall.productCode')"
        align="center"
        prop="id"
      />
      <el-table-column
        :label="$t('mall.productName')"
        align="center"
        prop="title"
      />
      <el-table-column
        :label="$t('mall.productDescription')"
        align="center"
        prop="description"
      />
      <el-table-column
        :label="$t('mall.price')"
        align="center"
        prop="price"
      />
      <el-table-column
        :label="$t('mall.stock')"
        align="center"
        prop="quantity"
      />
      <el-table-column
        column-key="status"
        prop="status"
        align="center"
        :label="$t('status.status')"
        :filters="statusFilters"
        :filter-multiple="false"
      >
        <template slot-scope="scope">
          <dict-tag
            :options="dict.type.goods_status"
            :value="scope.row.status"
          />
        </template>
      </el-table-column>
      <el-table-column
        column-key="goodsType"
        prop="goodsType"
        align="center"
        :label="$t('mall.productType')"
        :filters="goodsTypeFilters"
        :filter-multiple="false"
      >
        <template slot-scope="scope">
          <dict-tag
            :options="dict.type.goods_type"
            :value="scope.row.goodsType"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operate')"
        align="center"
        width="200"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 0 || scope.row.status == 2"
            size="mini"
            type="text"
            @click="upGoods(scope.row)"
          >{{ $t('mall.putOnSale') }}</el-button>
          <el-button
            v-if="scope.row.status == 1"
            size="mini"
            type="text"
            @click="downGoods(scope.row)"
          >{{ $t('mall.removeFromSale') }}</el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleUpdate(scope.row)"
          >{{ $t('update') }}</el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.row)"
          >{{ $t('remove') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import {
  myGoodsList,
  delMyGoods,
  downMyGoods,
  upMyGoods,
} from "../../api/mall/mallGoods";

export default {
  // name: "GoodsManager",
  dicts: ["goods_status", "goods_type", "social_platform", "file_type"],
  data() {
    return {
      // 按钮loading
      buttonLoading: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 商品表格数据
      mallGoodsList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        id: undefined,
        title: undefined,
        goodsType: undefined,
        status: undefined,
      },
    };
  },
  computed: {
    statusFilters() {
      return this.dict.type.goods_status.map((type) => ({
        text: type.label,
        value: type.value,
      }));
    },
    goodsTypeFilters() {
      return this.dict.type.goods_type.map((type) => ({
        text: type.label,
        value: type.value,
      }));
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 筛选
    filterChange(filter) {
      if (filter["status"]) {
        // 修改传给后端接口的参数，并重新调用接口
        this.queryParams.status = filter.status.join(",");
        this.handleQuery();
      } else if (filter["goodsType"]) {
        // 修改传给后端接口的参数，并重新调用接口
        this.queryParams.goodsType = filter.goodsType.join(",");
        this.handleQuery();
      }
    },
    /** 查询商品列表 */
    getList() {
      this.loading = true;
      myGoodsList(this.queryParams).then((response) => {
        this.mallGoodsList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    //上架
    upGoods(row) {
      upMyGoods(row.id).then((response) => {
        this.$modal.msgSuccess(this.$t("mall.saleSuccess"));
        this.getList();
      });
    },
    //下架
    downGoods(row) {
      downMyGoods(row.id).then((response) => {
        this.$modal.msgSuccess(this.$t("mall.removeSuccess"));
        this.getList();
      });
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({ path: "/addGoods" });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push({ path: `/addGoods/${row.id}` });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal
        .confirm(this.$t("mall.confirmDeleteProduct"))
        .then(() => {
          this.loading = true;
          return delMyGoods(ids);
        })
        .then(() => {
          this.loading = false;
          this.getList();
          this.$modal.msgSuccess(this.$t("tip.remove"));
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "system/mallGoods/export",
        {
          ...this.queryParams,
        },
        `mallGoods_${new Date().getTime()}.xlsx`
      );
    },
  },
};
</script>
<style scoped>
</style>
