var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "flex-row-center f-between w-100 mb10" }, [
        _c(
          "div",
          { staticClass: "f-shrink" },
          [
            _c(
              "el-button",
              {
                staticClass: "gradient-button",
                attrs: { icon: "el-icon-plus" },
                on: { click: _vm.handleAdd },
              },
              [_vm._v(_vm._s(_vm.$t("add")))]
            ),
            _c(
              "el-button",
              {
                staticClass: "button-white",
                attrs: { icon: "iconfont icon-delete", disabled: _vm.multiple },
                on: { click: _vm.handleDelete },
              },
              [_vm._v(_vm._s(_vm.$t("remove")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-row-center" },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.$t("refresh"),
                  placement: "top",
                },
              },
              [
                _c("i", {
                  staticClass: "iconfont icon-a-danyuanpeizhi6",
                  on: { click: _vm.getList },
                }),
              ]
            ),
            _c(
              "div",
              { staticStyle: { width: "400px", margin: "0 5px" } },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "account-input",
                    attrs: {
                      placeholder: _vm.$t("mall.enterProductName"),
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "title", $$v)
                      },
                      expression: "queryParams.title",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-input__icon iconfont icon-sousuotubiao",
                      attrs: { slot: "prefix" },
                      on: { click: _vm.handleQuery },
                      slot: "prefix",
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "header-row-class-name": "custom_table_header",
            height: "calc(100vh - 270px)",
            data: _vm.mallGoodsList,
          },
          on: {
            "filter-change": _vm.filterChange,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-empty", {
            attrs: { slot: "empty", "image-size": 180 },
            slot: "empty",
          }),
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.productCode"),
              align: "center",
              prop: "id",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.productName"),
              align: "center",
              prop: "title",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.productDescription"),
              align: "center",
              prop: "description",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.price"),
              align: "center",
              prop: "price",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.stock"),
              align: "center",
              prop: "quantity",
            },
          }),
          _c("el-table-column", {
            attrs: {
              "column-key": "status",
              prop: "status",
              align: "center",
              label: _vm.$t("status.status"),
              filters: _vm.statusFilters,
              "filter-multiple": false,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("dict-tag", {
                      attrs: {
                        options: _vm.dict.type.goods_status,
                        value: scope.row.status,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "column-key": "goodsType",
              prop: "goodsType",
              align: "center",
              label: _vm.$t("mall.productType"),
              filters: _vm.goodsTypeFilters,
              "filter-multiple": false,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("dict-tag", {
                      attrs: {
                        options: _vm.dict.type.goods_type,
                        value: scope.row.goodsType,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operate"),
              align: "center",
              width: "200",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0 || scope.row.status == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.upGoods(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("mall.putOnSale")))]
                        )
                      : _vm._e(),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.downGoods(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("mall.removeFromSale")))]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("update")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("remove")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }