<template>
  <div class="messageCenter">
    <div class="headerSelect">
      <!-- <el-checkbox-group v-model="mediaTypeList" @change="changeSelect" size="medium">
        <el-checkbox label="facebook" border></el-checkbox>
        <el-checkbox label="instagram" border></el-checkbox>
        <el-checkbox label="twitter" border></el-checkbox>
      </el-checkbox-group> -->
      <el-radio-group
        v-model="mediaTypeList"
        size="medium"
      >
        <el-radio
          label="facebook"
          border
        >Facebook</el-radio>
        <el-radio
          label="instagram"
          border
        >Instagram</el-radio>
        <el-radio
          label="twitter"
          border
        >X/Twitter</el-radio>
      </el-radio-group>
    </div>
    <div class="flexMessage">
      <dialogue
        :mediaTypeList="mediaTypeList"
        v-loading="accountLoading"
      />
      <div
        class="rightMessage"
        v-show="isShowMsg"
        v-loading="msgLoading"
      >
        <msg-header />
        <msg-content />
        <msg-input />
      </div>
      <div
        v-show="!isShowMsg"
        class="notMsg"
        v-loading="msgLoading"
      >
        <div class="notMsgContent">
          <img
            v-if="logo"
            :src="logo"
            class="notMsgLogo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dialogue from "./components/dialogue.vue";
import msgHeader from "./components/msgHeader.vue";
import msgContent from "./components/msgContent.vue";
import msgInput from "./components/msgInput.vue";
import logoImg from "@/assets/logo/logo.png";
export default {
  name: "message",
  components: {
    dialogue,
    msgHeader,
    msgContent,
    msgContent,
    msgInput,
  },
  data() {
    return {
      logo: logoImg,
      mediaTypeList: "facebook",
    };
  },
  computed: {
    ...mapState({
      isShowMsg: (state) => state.chinMsg.isShowMsg,
      msgLoading: (state) => state.chinMsg.msgLoading,
      accountLoading: (state) => state.chinMsg.accountLoading,
    }),
  },
  methods: {
    changeSelect(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.messageCenter {
  padding: 24px;
  .headerSelect {
    // height: 72px;
    // background: linear-gradient(
    //   rgba(255, 255, 255, 0.6) 0%,
    //   rgba(255, 255, 255, 0.2) 100%
    // );
    // box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    // padding: 0 24px;
    .el-checkbox {
      font-size: 14px;
      margin-right: 22px;
    }
    .el-checkbox.is-bordered.el-checkbox--medium {
      padding: 11px 20px 7px 10px;
      width: 140px;
      height: 40px;
      background: #ffffff;
      // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
    }
    .el-checkbox.is-bordered.is-checked {
      border-color: #0156ff;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #0156ff;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #0156ff;
      border-color: #0156ff;
    }
  }
  .flexMessage {
    display: flex;
    margin-top: 16px;
    .dialogue {
      width: 320px;
      height: calc(100vh - 220px);
      background: #ffffff;
      // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      border: 1px solid #ebebeb;
      margin-right: 16px;
      overflow: hidden;
    }
    .rightMessage {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 220px);
      background: #ffffff;
      // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      border: 1px solid #ebebeb;
    }
    .notMsg {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 220px);
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      border: 1px solid #ebebeb;
      padding: 16px;
      .notMsgContent {
        width: 100%;
        height: 100%;
        background: #e7eef5;
        border-radius: 10px;
        opacity: 0.2;
        display: flex;
        justify-content: center;
        align-items: center;
        .notMsgLogo {
          width: 160px;
        }
      }
    }
  }
}
</style>
