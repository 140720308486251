var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex-column video-item",
      class: { selected: _vm.item.selected },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleSelection($event)
        },
      },
    },
    [
      _c("div", { staticClass: "flex-center item-image" }, [
        _c("img", {
          staticClass: "image-cover",
          attrs: { src: _vm.getCover(_vm.item.coverUrl), alt: "image" },
        }),
      ]),
      _c("div", [
        _c("div", { staticClass: "video-name" }, [
          _vm._v(_vm._s(_vm.item.title)),
        ]),
        _vm.item.description
          ? _c(
              "div",
              { staticClass: "video-description single-line-ellipsis" },
              [_vm._v(_vm._s(_vm.item.description))]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "flex-row-center f-between" }, [
        _c(
          "div",
          {
            staticClass: "single-line-ellipsis f-grow",
            staticStyle: { color: "blue", "font-size": "12px" },
          },
          [_vm._v(" " + _vm._s(_vm.item.businessType) + " ")]
        ),
        _c("span", { staticClass: "f-shrink" }, [
          _vm._v(
            _vm._s(_vm.$t("mall.residual")) + "：" + _vm._s(_vm.item.quantity)
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }