<!-- 商品详情 -->
<template>
  <div class="app-container" v-loading="loading" style="padding: 30px 10%;">
    <div v-if="detail.sellNickName">
      <span class=""> {{ $t('mall.sellerInformation') }}</span>
      <span class="ml10"> {{ detail.sellNickName }}</span>
    </div>
    <div class="flex-row-center mt20" >
      <!-- <video :src="detail.originalUrl" controls alt="video" class="video-cover" /> -->
      <el-carousel v-if="coverImages.length > 1" indicator-position="outside" class="carousel-video">
        <el-carousel-item v-for="(url, index) in coverImages" :key="index">
          <img :src="url" alt="image" class="image-cover" />
        </el-carousel-item>
      </el-carousel>
      <img v-else :src="coverImages && coverImages[0]" alt="image" class="image-cover" />
      <!-- <img :src="detail.coverUrl" alt="image" class="image-cover" /> -->
      <div class="ml20 flex-column" style="justify-content: space-between;min-height: 300px;">
        <div class="video-name">{{ detail.title }}</div>
        <div class="video-description mt5" v-if="detail.description">{{ detail.description }}</div>
        <!-- <div>{{ $t('mall.materialDuration') }}：{{detail.duration}}</div> -->
        <div class="mt5">{{ $t('mall.discountPrice') }}：
          <span style="font-size: 20px;color: red;">¥{{detail.price}}</span>
        </div>
        <div class="strikethrough mt5" v-if="detail.originalPrice">{{ $t('mall.originalPrice') }}：¥{{detail.originalPrice}}</div>
        <div class="flex-row-center mt5">
          <el-button :loading="loadingCart" @click="addCart" :disabled="detail.inCart">{{ detail.inCart ? $t('mall.addedToCart') : $t('mall.addToCart') }}</el-button>
          <el-button :loading="loadingBuy" type="primary" @click="createOrder">{{ $t('mall.buyNow') }}</el-button>
        </div>
      </div>
    </div>
    <div class="video-name mt20">{{ $t('mall.productDetails') }}</div>
    <div class="flex-center mt5">
      <video :src="detail.originalUrl" controls alt="video" class="video-cover" />
    </div>
    <div v-if="detail.detail" class="mt20" v-html="detail.detail"></div>
    <PayDialog
      ref="payDialog"
      :visible.sync="payVisible"
      :orderInfo="orderInfo"
      @paySuccess="paySuccess"
    />
  </div>
</template>

<script>
import { addMallCart } from '../../api/mall/mallCartItems';
import { getMallGoods } from '../../api/mall/mallMaterial';
import { createMallOrder } from '../../api/mall/mallOrder';
import PayDialog from "./PayDialog.vue";
export default {
  name: 'GoodsDetail',
  components: {
    PayDialog
  },
  data() {
    return {
      // selected: false,  // 添加选中状态
      loading: false,
      id: '',
      detail: {},
      coverImages: [],//可能存在的轮播图——封面
      loadingCart: false,
      loadingBuy: false,
      payVisible: false,//支付弹窗
      orderInfo: null,//支付订单数据
    };
  },
  created(){
    this.id = this.$route.params.id || '';
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      this.loading = true
      getMallGoods(this.id).then(res => {
        this.detail = res.data
        if(this.detail.coverUrl){
          this.coverImages = this.detail.coverUrl.split(',')
        }
      }).finally(() => {
        this.loading = false
      });
      ;
    },
    //加入购物车
    addCart(){
      this.loadingCart = true
      addMallCart({
        mallGoodsId:this.detail.id,
        count:1
      }).then(res=>{
        this.$modal.msgSuccess(this.$t('mall.addToCartSuccess'));
      }).finally(()=>{
        this.loadingCart = false
      })
    },
    createOrder(){
      this.loadingBuy = true
      createMallOrder({
        receiveEmail:'',
        createOrderGoodsList:[{
          // id:this.detail.id,
          mallGoodsId:this.detail.id,
          count:1
        }]
      }).then(res=>{
        this.orderInfo = res.data
        this.payVisible = true
        this.$nextTick().then((rs) => {
          this.$refs.payDialog.init();
        });
      }).finally(()=>{
        this.loadingBuy = false
      })
    },
    paySuccess(){
      this.$tab.closeOpenPage({path: "/mall/orderCenter"});
    },
  }
};
</script>

<style scoped>
.image-cover {
  /* max-width: 300px;
  max-height: 300px;
  width: auto;
  height: auto; */
  width: 350px;
  height: 350px;
}
.video-cover {
  /* max-width: 500; */
  width: 100%;
  max-height: 500px;
  width: auto;
  height: auto;
}
.video-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.video-description{
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.strikethrough{
  text-decoration: line-through; /* 添加划线效果 */
  color: #999;                   /* 可选：调整颜色以区分原价 */
}

.carousel-video{
  /* max-width: 50%;
  min-width: 200px; */
  width: 350px;
  height: 350px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
