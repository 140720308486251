var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "messageCenter" }, [
    _c(
      "div",
      { staticClass: "headerSelect" },
      [
        _c(
          "el-radio-group",
          {
            attrs: { size: "medium" },
            model: {
              value: _vm.mediaTypeList,
              callback: function ($$v) {
                _vm.mediaTypeList = $$v
              },
              expression: "mediaTypeList",
            },
          },
          [
            _c("el-radio", { attrs: { label: "facebook", border: "" } }, [
              _vm._v("Facebook"),
            ]),
            _c("el-radio", { attrs: { label: "instagram", border: "" } }, [
              _vm._v("Instagram"),
            ]),
            _c("el-radio", { attrs: { label: "twitter", border: "" } }, [
              _vm._v("X/Twitter"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flexMessage" },
      [
        _c("dialogue", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.accountLoading,
              expression: "accountLoading",
            },
          ],
          attrs: { mediaTypeList: _vm.mediaTypeList },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowMsg,
                expression: "isShowMsg",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.msgLoading,
                expression: "msgLoading",
              },
            ],
            staticClass: "rightMessage",
          },
          [_c("msg-header"), _c("msg-content"), _c("msg-input")],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isShowMsg,
                expression: "!isShowMsg",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.msgLoading,
                expression: "msgLoading",
              },
            ],
            staticClass: "notMsg",
          },
          [
            _c("div", { staticClass: "notMsgContent" }, [
              _vm.logo
                ? _c("img", {
                    staticClass: "notMsgLogo",
                    attrs: { src: _vm.logo },
                  })
                : _vm._e(),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }