var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "publicDialog",
      attrs: {
        visible: _vm.visible,
        "before-close": _vm.handleBeforeClose,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "headerFlex" }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.$t("tip.tip"))),
          ]),
          _c("i", {
            staticClass: "el-icon-close",
            on: { click: _vm.handleCancel },
          }),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("mall.emailNote")))]),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mall.email"), prop: "email" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { maxlength: "50" },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "cancelBtn btnWidth120",
              on: { click: _vm.handleCancel },
            },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "submitBtn btnWidth120",
              on: { click: _vm.handleConfirm },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }