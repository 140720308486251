var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialogue" },
    [
      _c(
        "div",
        { staticClass: "topPadding" },
        [
          _c("div", { staticClass: "topTitle" }, [
            _vm._v(_vm._s(_vm.$t("messageCenter.title"))),
          ]),
          _c(
            "el-input",
            {
              staticClass: "inputStyle",
              attrs: {
                clearable: "",
                placeholder: _vm.$t("messageCenter.placeholder"),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleQuery($event)
                },
              },
              model: {
                value: _vm.searchValue,
                callback: function ($$v) {
                  _vm.searchValue = $$v
                },
                expression: "searchValue",
              },
            },
            [
              _c("i", {
                staticClass: "el-input__icon iconfont icon-sousuotubiao",
                attrs: { slot: "prefix" },
                on: { click: _vm.handleQuery },
                slot: "prefix",
              }),
            ]
          ),
        ],
        1
      ),
      _vm.accountList.length == 0
        ? _c("el-empty", { attrs: { "image-size": 200 } })
        : _vm._e(),
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        _vm._l(_vm.accountList, function (item, parentIndex) {
          return _c(
            "div",
            { key: item.parentIndex },
            [
              item.children && item.children.length
                ? _c(
                    "el-collapse-item",
                    {
                      attrs: { name: item.parentIndex },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "accountTitle" }, [
                                  _c("div", { staticClass: "criceIconUser" }, [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-zhanghaoguanli",
                                    }),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("messageCenter.account")) +
                                      " : " +
                                      _vm._s(item.account) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    _vm._l(item.children, function (account, childIndex) {
                      return _c(
                        "div",
                        {
                          key: account.id,
                          class: [
                            "accountBox",
                            account.isActive ? "activeBox" : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.clickAccount(parentIndex, childIndex)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "leftAvatar" },
                            [
                              _c("el-avatar", {
                                attrs: { size: 44, src: account.profileImage },
                              }),
                              _c("div", { staticClass: "criceIcon" }, [
                                _c("i", {
                                  class: [
                                    "iconfont",
                                    _vm.filterIcon(account.platform),
                                  ],
                                }),
                              ]),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "rightUserDetail" }, [
                            _c("div", { staticClass: "detailUserTop" }, [
                              _c("div", { staticClass: "userTitle" }, [
                                _vm._v(_vm._s(account.nickname)),
                              ]),
                              _c("div", { staticClass: "userTime" }, [
                                _vm._v(_vm._s(account.updateTime)),
                              ]),
                            ]),
                            _c("div", { staticClass: "detailMsg" }, [
                              _vm._v(_vm._s(account.message)),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }