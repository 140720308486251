<template>
  <div class="dialogue">
    <div class="topPadding">
      <div class="topTitle">{{ $t('messageCenter.title') }}</div>
      <el-input
        class="inputStyle"
        v-model="searchValue"
        clearable
        :placeholder="$t('messageCenter.placeholder')"
        @keyup.enter.native="handleQuery"
      >
        <i
          slot="prefix"
          class="el-input__icon iconfont icon-sousuotubiao"
          @click="handleQuery"
        ></i>
      </el-input>
    </div>
    <el-empty
      v-if="accountList.length==0"
      :image-size="200"
    ></el-empty>
    <!-- 账号折叠面板 -->
    <el-collapse v-model="activeNames">
      <div
        v-for="(item, parentIndex) in accountList"
        :key="item.parentIndex"
      >
        <el-collapse-item
          :name="item.parentIndex"
          v-if="item.children && item.children.length"
        >
          <template #title>
            <div class="accountTitle">
              <div class="criceIconUser">
                <i class="iconfont icon-zhanghaoguanli"></i>
              </div>
              {{ $t('messageCenter.account') }} : {{ item.account }}
            </div>
          </template>
          <div
            v-for="(account, childIndex) in item.children"
            :key="account.id"
            :class="['accountBox', account.isActive ? 'activeBox' : '']"
            @click="clickAccount(parentIndex, childIndex)"
          >
            <div class="leftAvatar">
              <el-avatar
                :size="44"
                :src="account.profileImage"
              ></el-avatar>
              <div class="criceIcon">
                <i :class="['iconfont', filterIcon(account.platform)]"></i>
              </div>
            </div>
            <div class="rightUserDetail">
              <div class="detailUserTop">
                <div class="userTitle">{{ account.nickname }}</div>
                <div class="userTime">{{ account.updateTime }}</div>
              </div>
              <div class="detailMsg">{{ account.message }}</div>
            </div>
          </div>
        </el-collapse-item>
      </div>
    </el-collapse>
  </div>
</template>

<script>
import EventBus from "@/utils/EventBus";
import { mapState } from "vuex";
export default {
  name: "dialogue",
  components: {},
  props: {
    mediaTypeList: {
      require: true,
      default: "facebook",
    },
  },
  data() {
    return {
      searchValue: "",
      platform: "",
      activeNames: "1",
      // accountList: [
      //   {
      //     id: 1,
      //     account: '6541656161565',
      //     children: [
      //       {
      //         avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //         platform: 'youtube',
      //         userName: 'JACK akscbaskcaks',
      //         time: '12:30',
      //         content: '我和你说，就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说',
      //         isActive: false
      //       },{
      //         avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //         platform: 'tiktok',
      //         userName: 'JACK akscbaskcaks',
      //         time: '12:30',
      //         content: '我和你说，就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说',
      //         isActive: false
      //       },{
      //         avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //         platform: 'youtube',
      //         userName: 'JACK akscbaskcaks',
      //         time: '12:30',
      //         content: '我和你说，就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说',
      //         isActive: false
      //       }
      //     ]
      //   },
      //   {
      //     id: 2,
      //     account: '12124345',
      //     children: [
      //      {
      //         avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //         platform: 'tiktok',
      //         userName: 'JACK akscbaskcaks',
      //         time: '12:30',
      //         content: '我和你说，就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说',
      //         isActive: false
      //       },{
      //         avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //         platform: 'youtube',
      //         userName: 'JACK akscbaskcaks',
      //         time: '12:30',
      //         content: '我和你说，就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说',
      //         isActive: false
      //       }
      //     ]
      //   },
      //   {
      //     account: '888886161565',
      //     id: 3,
      //     children: [
      //      {
      //         avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //         platform: 'tiktok',
      //         userName: 'JACK akscbaskcaks',
      //         time: '12:30',
      //         content: '我和你说，就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说',
      //         isActive: false
      //       },{
      //         avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //         platform: 'youtube',
      //         userName: 'JACK akscbaskcaks',
      //         time: '12:30',
      //         content: '我和你说，就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说',
      //         isActive: false
      //       }
      //     ]
      //   },
      //   {
      //     id: 4,
      //     account: '090901316161565',
      //     children: [
      //      {
      //         avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //         platform: 'tiktok',
      //         userName: 'JACK akscbaskcaks',
      //         time: '12:30',
      //         content: '我和你说，就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说',
      //         isActive: false
      //       },{
      //         avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //         platform: 'youtube',
      //         userName: 'JACK akscbaskcaks',
      //         time: '12:30',
      //         content: '我和你说，就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说就是我和你说',
      //         isActive: false
      //       }
      //     ]
      //   }
      // ]
    };
  },
  created() {
    this.searchFun();
  },
  watch: {
    mediaTypeList: {
      handler(newVal) {
        this.platform = newVal;
        this.searchFun();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      accountList: (state) => state.chinMsg.accountList,
    }),
  },
  methods: {
    handleQuery() {
      this.searchFun();
    },
    searchFun() {
      this.$store.dispatch("chinMsg/getMsgList", {
        platform: this.platform ? this.platform : "facebook",
        searchValue: this.searchValue,
      });
    },
    clickAccount(t, n) {
      const info = this.accountList[t].children[n];
      this.accountList.forEach((item) => {
        const { children } = item;
        if (children && children.length) {
          children.forEach((item) => {
            item.isActive = false;
          });
        }
      });
      console.log(info);
      EventBus.$emit("proceed-chat", info);
      this.accountList[t].children[n].isActive = true;
      this.$store.dispatch("chinMsg/getDetail", info);
      // info
    },
    filterIcon(name) {
      let icon;
      switch (name) {
        case "youtube":
          icon = "icon-youtube-normal";
          break;
        case "tiktok":
          icon = "icon-tiktok-normal";
          break;
        case "facebook":
          icon = "icon-facebook-normal";
          break;
        case "linkedin":
          icon = "icon-linkedin-normal";
          break;
        case "twitter":
          icon = "icon-twitter-normal";
          break;
        case "instagram":
          icon = "icon-instagram-normal";
          break;
        case "reddit":
          icon = "icon-reddit-normal";
          break;
        case "gmb":
          icon = "icon-gmb-normal";
          break;
        case "pinterest":
          icon = "icon-pinterest-normal";
          break;
        case "telegram":
          icon = "icon-telegram-normal";
          break;
      }
      return icon;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
.dialogue {
  .topPadding {
    padding: 14px 16px;
    .topTitle {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 20px;
      text-align: left;
      font-style: normal;
      margin-bottom: 22px;
    }
    .inputStyle {
      background: #ffffff;
      border-radius: 8px;
      .el-input__inner {
        height: 32px;
        border-radius: 8px !important;
      }
      .el-input__icon {
        line-height: 32px;
      }
    }
  }
  .el-collapse {
    border: none;
    height: 75%;
    overflow: auto;
    padding: 0 16px;
    padding-bottom: 50px;
    @include chatScrollBar;
    .el-collapse-item__header {
      border-bottom: 1px solid transparent;
    }
    .el-collapse-item__wrap {
      border-bottom: 1px solid transparent;
    }
    .accountTitle {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #016fee;
      line-height: 24px;
      text-align: left;
      font-style: normal;
      display: flex;
      align-items: center;
      .criceIconUser {
        width: 24px;
        height: 24px;
        background: #deedfe;
        border-radius: 57px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-zhanghaoguanli {
          color: #016fee;
        }
      }
    }
    .accountBox {
      cursor: pointer;
      width: 100%;
      // height: 64px;
      height: 74px;
      max-height: 74px;
      background: #ffffff;
      padding: 0 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .leftAvatar {
        width: 45px;
        height: 45px;
        position: relative;
        .criceIcon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #fff;
          border: 1px solid #dcdcdc;
          position: absolute;
          bottom: -3px;
          right: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconfont {
            font-size: 12px;
          }
          .icon-youtube-normal {
            font-size: 10px;
          }
        }
      }
      .rightUserDetail {
        width: 100%;
        margin-left: 10px;
        .detailUserTop {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .userTitle {
            width: 85px;
            font-family: PingFang-SC, PingFang-SC;
            font-weight: bold;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
            line-height: 20px;
            text-align: left;
            font-style: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .userTime {
            font-family: PingFang-SC, PingFang-SC;
            font-weight: 400;
            font-size: 12px;
            color: #bfccdf;
            line-height: 17px;
            text-align: left;
            font-style: normal;
          }
        }
        .detailMsg {
          margin-top: 8px;
          width: 190px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }
      }
    }
    .activeBox {
      background: #f2f8fe;
      .criceIcon {
        border: 1px solid #0156ff !important;
      }
    }
  }

  .icon-quanbuzhanghao {
    color: #0156ff !important;
  }
  .icon-youtube-normal {
    color: #db0200;
  }
  .icon-tiktok-normal {
    background: #333333;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .icon-facebook-normal {
    color: #1a77f3;
  }
  .icon-linkedin-normal {
    color: #0474b3;
  }
  .icon-twitter-normal {
    color: #004da5;
  }
  .icon-instagram-normal {
    background: linear-gradient(221deg, #d82a81 0%, #ffcb5c 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .icon-reddit-normal {
    font-size: 19px;
    color: #ff4500;
  }
  .icon-gmb-normal {
    color: #0096ff;
  }
  .icon-pinterest-normal {
    color: #cc1f28;
  }
  .icon-telegram-normal {
    color: #0072ff;
  }
}
</style>
