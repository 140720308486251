<template>
  <div class="login-container flex-center">
    <div class="login-main flex-row">
      <div class="login-bg-50 flex-column-center">
        <img
          src="@/assets/images/login/bg-small.jpg"
          alt=""
          class="login-img-left"
          @load="onImageLoad"
        />
        <lang-select class="set-language self-end" />
        <div
          class="left-logo"
          v-show="isOnLoad"
        >InfoPub</div>
        <div
          class="left-des f-grow"
          v-show="isOnLoad"
        >{{ $t('login.smartPublishSystem') }}</div>
        <div
          class="left-bottom"
          v-show="isOnLoad"
        >{{ $t('login.oneClickPublish') }}</div>
      </div>
      <div
        v-if="wxType === 0"
        class="login-bg-50 flex-column"
      >
        <!-- <div
          class="right-wx"
          @click.stop="wxlogin()"
        >
          <img
            class="wx-logo pointer"
            src="@/assets/images/login/login-rq.png"
            :alt="$t('login.wxLogin')"
          />
        </div> -->
        <div class="flex-row-center">
          <img
            src="@/assets/logo/logo.png"
            alt=""
            class="login-logo"
          />
        </div>
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
        >
          <div
            class="flex-row-center between"
            style="margin-bottom: 32px;"
          >
            <div class="title-container flex-column-center">
              <div
                class="title"
                :style="loginForm.loginType !== 'username' ? 'color:rgba(0,0,0,0.4);':'color:black;'"
                @click="changeLoginType('username')"
              >{{ $t('login.accountLogin') }}</div>
              <div
                class="title-line"
                v-if="loginForm.loginType == 'username'"
              ></div>
            </div>
            <div class="title-container flex-column-center">
              <div
                class="title"
                :style="loginForm.loginType !== 'phone' ? 'color:rgba(0,0,0,0.4);':'color:black;'"
                @click="changeLoginType('phone')"
              >{{ $t('login.mobileLogin') }}</div>
              <div
                class="title-line"
                v-if="loginForm.loginType == 'phone'"
              ></div>
            </div>
          </div>
          <div v-if="loginForm.loginType === 'username'">
            <el-form-item
              prop="username"
              label-width="0"
            >
              <el-input
                :name="usernameInputName"
                :autocomplete="autocompleteOff"
                v-model="loginForm.username"
                type="text"
                auto-complete="off"
                :placeholder="$t('loginNew.inputAccount')"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                :name="passwordInputName"
                :autocomplete="autocompleteOff"
                v-model="loginForm.password"
                :type="showPassword ? 'text' : 'password'"
                auto-complete="off"
                :placeholder="$t('loginNew.inputPassword')"
                @keyup.enter.native="handleLogin"
              >
                <div
                  class="el-input__icon input-icon passwrod-eye"
                  slot="suffix"
                >
                  <i
                    class="iconfont"
                    :class="showPassword ? 'icon-zhengyan' : 'icon-biyan'"
                    @click="togglePasswordVisibility"
                  ></i>
                </div>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="code"
              v-if="captchaEnabled"
            >
              <el-input
                v-model="loginForm.code"
                auto-complete="off"
                :placeholder="$t('loginNew.inputCaptcha')"
                @keyup.enter.native="handleLogin"
              >
                <div
                  class="el-input__icon input-icon login-code"
                  slot="suffix"
                >
                  <img
                    :src="codeUrl"
                    @click="getCode"
                    class="login-code-img"
                  />
                </div>
              </el-input>
            </el-form-item>
            <!-- <el-checkbox v-model="loginForm.rM" style="margin: 0px 0px 25px 0px">
              {{$t('login.rememberMe')}}
            </el-checkbox> -->
          </div>
          <div v-else>
            <el-form-item
              prop="phone"
              label-width="0"
            >
              <el-input
                v-model="loginForm.phone"
                type="text"
                auto-complete="off"
                :placeholder="$t('loginNew.inputPhone')"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="imgCode">
              <el-input
                v-model="loginForm.imgCode"
                auto-complete="off"
                :placeholder="$t('login.imageVerificationCode')"
              >
                <div
                  class="el-input__icon input-icon login-code"
                  slot="suffix"
                >
                  <img
                    class="login-code-img"
                    :src="codeUrl"
                    @click="getCode"
                  />
                </div>
              </el-input>

            </el-form-item>
            <el-form-item prop="code">
              <el-input
                v-model="loginForm.code"
                auto-complete="off"
                :placeholder="$t('login.verificationCode')"
              >
                <div
                  slot="suffix"
                  class="el-input__icon input-icon phone-code"
                >
                  <div
                    class="phone-code-img"
                    v-loading="phoneLoading"
                    @click="sendPhone()"
                    v-if="!isDisabled"
                  >{{ phoneContent }}
                  </div>
                  <div
                    class="phone-code-img-disabled"
                    v-if="isDisabled"
                  >{{ phoneContent }}</div>
                </div>

              </el-input>

            </el-form-item>
          </div>
          <div class="flex-row-center f-between mt16 ml10 mr10 login-bottom">
            <div
              v-if="register"
              style="text-align: center"
            >
              <span
                style="color: #194BFB;cursor: pointer;font-size: 14px;"
                @click="gotoRegister"
              >{{ $t('loginNew.registerAccount') }}</span>
            </div>
            <span
              v-if="loginForm.loginType === 'username'"
              style="color: rgba(0,0,0,0.4);cursor: pointer;font-size: 14px;"
              @click="gotoRorgot"
            >{{ $t('loginNew.forgotPassword') }}</span>
          </div>
          <el-button
            :loading="loading"
            class="login-btn mt8"
            @click.native.prevent="handleLogin"
          >
            <span v-if="!loading">{{ $t('login.logIn') }}</span>
            <span v-else>{{ $t('login.logIng') }}</span>
          </el-button>
          <!-- <div v-if="register" style="text-align: center">
            <span style="color: #194BFB;cursor: pointer" @click="quickRegister">{{ $t('login.quickSignUp') }}</span>
          </div> -->
          <el-form-item prop="agreed">
            <div class="flex-row-center mt10">
              <input
                type="checkbox"
                v-model="loginForm.agreed"
              />
              <span
                class="flex-row-center f-wrap ml8"
                style="line-height: 20px"
              >
                {{$t('agreed.readAndAgree')}}
                <el-link
                  type="primary"
                  :underline="false"
                  class="ml5 mr5"
                  style="vertical-align: middle;color:#194BFB;"
                  href="/service"
                  target="_blank"
                >{{$t('agreed.userAgreement')}}</el-link>
                <span class="mr5">
                  {{$t('agreed.and')}}
                </span>
                <el-link
                  type="primary"
                  :underline="false"
                  style="vertical-align: middle;color:#194BFB;"
                  href="/privacy"
                  target="_blank"
                >{{$t('agreed.privacyPolicy')}}</el-link>
              </span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div
        v-else-if="wxType === 1"
        class="login-bg-50 flex-column"
      >
        <div
          class="right-wx"
          @click.stop="turnBind"
        >
          <img
            class="wx-logo pointer"
            src="@/assets/images/login/login-computer.png"
            :alt="$t('login.wxLogin')"
          />
        </div>
        <div class="wx-title">{{ $t('wx.scanLogin') }}</div>
        <div class="wx-tip">{{ $t('wx.scanWithWeChat') }}</div>
        <div class="flex-center">
          <div
            class="qr-code flex-center"
            @click="bindTimeout=!bindTimeout"
            v-loading="wxQrLoading"
          >
            <vue-qr
              :text="qrUrl"
              :size="318"
            ></vue-qr>
            <div
              v-if="bindTimeout"
              class="wx-code-over flex-column-center f-center"
            >
              <i
                class="el-icon-refresh mb8"
                @click="wxlogin"
              ></i>
              <span>{{ $t('wx.qrCodeExpired') }}</span>
              <span>{{ $t('wx.clickRefresh') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="wxType === 2"
        class="login-bg-50 flex-column"
      >
        <div
          class="right-wx"
          @click.stop="backLogin"
        >
          <img
            class="wx-logo pointer"
            src="@/assets/images/login/login-computer.png"
            :alt="$t('login.wxLogin')"
          />
        </div>
        <div class="wx-title">{{ $t('wx.bindPhone') }}</div>
        <div class="wx-tip">{{ $t('wx.bindPhoneTip') }}</div>
        <el-form
          ref="wxBindForm"
          :model="wxBindForm"
          :rules="wxBindRules"
          class="login-form"
        >
          <el-form-item prop="email">
            <el-input
              v-model="wxBindForm.email"
              type="text"
              autocomplete="off"
              maxlength="50"
              :placeholder="$t('newRegister.inputEmailOrPhone')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              v-model="wxBindForm.code"
              autocomplete="off"
              maxlength="10"
              :placeholder="$t('newRegister.inputCode')"
              @keyup.enter.native="handleBind"
            >
              <div
                slot="suffix"
                class="el-input__icon input-icon phone-code"
              >
                <div
                  class="phone-code-img"
                  v-loading="codeLoding"
                  @click="sendCode"
                  v-if="!isDisabled"
                >{{ content }}
                </div>
                <div
                  class="phone-code-img-disabled"
                  v-if="isDisabled"
                >{{ content }}</div>
              </div>
            </el-input>
          </el-form-item>
          <el-button
            :loading="loading"
            class="login-btn bind-btn-mt"
            @click.native.prevent="handleBind"
          >
            <span v-if="!loading">{{ $t('bind') }}</span>
            <span v-else>{{ $t('binding') }}</span>
          </el-button>
          <el-form-item
            prop="article"
            style="margin-top: 12px;"
          >
            <div class="flex-row-center mt10">
              <input
                type="checkbox"
                v-model="wxBindForm.article"
              />
              <span
                class="flex-row-center f-wrap ml8"
                style="line-height: 20px"
              >
                {{$t('agreed.readAndAgree')}}
                <el-link
                  type="primary"
                  :underline="false"
                  class="ml5 mr5"
                  style="vertical-align: middle;color:#194BFB;"
                  href="/service"
                  target="_blank"
                >{{$t('agreed.userAgreement')}}</el-link>
                <span class="mr5">
                  {{$t('agreed.and')}}
                </span>
                <el-link
                  type="primary"
                  :underline="false"
                  style="vertical-align: middle;color:#194BFB;"
                  href="/privacy"
                  target="_blank"
                >{{$t('agreed.privacyPolicy')}}</el-link>
              </span>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--  底部  -->
    <div class="el-login-footer">
      <span>Copyright © 2022-2025 Infonity AI </span>
    </div>
  </div>
</template>

<script>
import LangSelect from "@/components/LangSelect";
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { decrypt, encrypt } from "@/utils/jsencrypt";
import { sendEmilCode, sendMyPhone } from "@/api/base/resource";
import {
  createFreeAccount,
  getLoginUUid,
  loginwx,
  QRcode,
  setPhone,
  uuidLogin,
} from "../api/system/user";
import vueQr from "vue-qr";

export default {
  name: "Login",
  components: {
    LangSelect,
    vueQr,
  },
  data() {
    return {
      isOnLoad: false, // 图片是否加载完成
      isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
      content: this.$t("login.getSMSCode"),
      phoneContent: this.$t("login.getSMSCode"),
      codeUrl: "",
      sendSmsForm: {
        imgCode: "",
        uuid: "",
      },
      loginForm: {
        loginType: "username",
        email: "",
        username: "",
        password: "",
        rM: true,
        agreed: true,
        code: "",
        uuid: "",
      },
      loginRules: {
        agreed: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t("agreed.prompt")));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipEmail"),
          },
          {
            pattern:
              /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: this.$t("login.tipCorrectEmail"),
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipPhone"),
          },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: this.$t("login.tipCorrectPhone"),
            trigger: ["blur", "change"],
          },
        ],
        username: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipAccount"),
          },
        ],
        password: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipPassword"),
          },
        ],
        code: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipCode"),
          },
        ],
        imgCode: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipCode"),
          },
        ],
      },
      loading: false,
      phoneLoading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: true,
      redirect: undefined,
      usernameInputName: "usernameInput",
      passwordInputName: "passwordInput",
      autocompleteOff: "new-password",
      showPassword: false, // 用于切换密码的显示/隐藏状态
      timer: null,
      wxTimer: null,
      bindWxVisible: false,
      bindTimeout: false,
      qrUrl: "",
      wxType: 0, // 0-正常登录页面 1-二维码页面 2：绑定手机号/邮箱页面
      wxQrLoading: false, //微信二维码的loading
      codeType: "", //微信二维码登录绑定的手机或邮箱类型
      codeLoding: false,
      wxBindForm: {
        email: "",
        article: false,
        code: "",
      },
      wxBindRules: {
        article: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t("agreed.prompt")));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipEmail"),
          },
          { validator: this.validateEmailOrPhone, trigger: ["blur", "change"] },
          // {
          //   pattern: /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
          //   message: this.$t('login.tipCorrectEmail'),
          //   trigger: ['blur', 'change']
          // },
        ],
        code: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("register.enterVerificationCode"),
          },
        ],
      },
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
    //切换登录类型时，清空必填项提示
    "loginForm.loginType": function (newType) {
      // 更新输入字段名称以避免浏览器自动填充
      this.usernameInputName =
        newType === "username" ? "usernameInput" : `usernameInput-${newType}`;
      this.passwordInputName =
        newType === "username" ? "passwordInput" : `passwordInput-${newType}`;
      this.$nextTick(() => {
        this.$refs.loginForm.clearValidate();
      });
    },
  },
  created() {
    // 判断是否为手机浏览器
    if (this.isMobile()) {
      // 如果是手机浏览器，跳转到跨域地址
      this.redirectToMobileSite();
    } else {
      this.getCode();
      this.getCookie();
    }
  },
  mounted() {
    // DOM 渲染（$nextTick）：Vue 会先渲染和更新 DOM，然后才会执行 this.$nextTick() 中的回调。也就是说，当 this.$nextTick() 执行时，DOM 已经完成更新。
    // 图片加载（@load）：@load 事件是在图片加载完成后触发的，它是在浏览器加载图片资源时发生的。浏览器会先加载图片，然后触发 @load 事件。
    // 所以，img 加载完毕会比 DOM 渲染和 Vue 更新（$nextTick）要晚，因为图片加载是一个独立的过程，和 DOM 渲染的过程并不完全同步。
    // dom加载完毕之后，展示文字
    // this.$nextTick(() => {
    //   this.isOnLoad = true;
    // });
  },
  methods: {
    // 绑定手机号或邮箱
    handleBind() {
      this.$refs.wxBindForm.validate((valid) => {
        if (valid) {
          if (!this.wxBindForm.article) {
            this.$modal.msgWarning(this.$t("register.acceptTerms"));
            return;
          }
          this.loading = true;
          this.wxBindForm.username = this.wxBindForm.email;
          if (this.codeType === "phone") {
            setPhone({
              phonenumber: this.wxBindForm.email,
              code: this.wxBindForm.code,
            }).then((res) => {
              this.$router.push({ path: this.redirect || "/" }).catch(() => {});
            });
          } else if (this.codeType === "email") {
            setMail({
              email: this.wxBindForm.email,
              code: this.wxBindForm.code,
            }).then((res) => {
              this.$router.push({ path: this.redirect || "/" }).catch(() => {});
            });
          }
        }
      });
    },
    validateEmailOrPhone(rule, value, callback) {
      // 邮箱正则表达式
      const emailPattern =
        /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      // 手机号正则表达式 (中国手机号格式)
      const phonePattern = /^1[3-9]\d{9}$/;

      // 校验邮箱或手机号
      if (emailPattern.test(value)) {
        this.codeType = "email"; // 如果是邮箱，设置 inputType 为 'email'
        callback();
      } else if (phonePattern.test(value)) {
        this.codeType = "phone"; // 如果是手机号，设置 inputType 为 'phone'
        callback();
      } else {
        callback(new Error(this.$t("newRegister.tipCorrectEmailOrPhone")));
      }
    },
    // 发送验证码
    sendCode() {
      const emailOrPhone = this.wxBindForm.email;
      if (!this.codeType || !emailOrPhone) {
        this.$refs.wxBindForm.validateField("email");
        return;
      }
      const isValidEmail =
        /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          emailOrPhone
        );
      const isValidPhone = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(emailOrPhone);
      if (
        (this.codeType === "email" && !isValidEmail) ||
        (this.codeType === "phone" && !isValidPhone)
      ) {
        this.$refs.wxBindForm.validateField("email");
        return;
      }
      if (this.codeType === "email") {
        this.sendWxBindEmail();
      } else if (this.codeType === "phone") {
        this.sendWxBindPhone();
      }
    },
    // 发送邮箱验证码
    sendWxBindEmail() {
      let vm = this;
      vm.content = "";
      this.codeLoding = true;
      // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
      sendEmilCode({
        sendType: "register",
        email: vm.wxBindForm.email,
      })
        .then((res) => {
          if (res.code === 200) {
            this.codeLoding = false;
            vm.isDisabled = false;
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.content = vm.count + this.$t("login.later");
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true;
                // 计时秒数
                vm.count--;
                // 更新按钮的文字内容
                vm.content = vm.count + this.$t("login.later");
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false;
                // 更新按钮文字内容
                vm.content = this.$t("login.getSMSCode");
                // 清空定时器!!!
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.codeLoding = false;
          vm.content = this.$t("login.getSMSCode");
        });
    },
    // 发送手机验证码
    sendWxBindPhone() {
      let vm = this;
      vm.content = "";
      this.codeLoding = true;
      // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
      sendMyPhone({
        sendType: "register",
        phone: vm.wxBindForm.email,
      })
        .then((res) => {
          if (res.code === 200) {
            this.codeLoding = false;
            vm.isDisabled = false;
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.content = vm.count + this.$t("login.later");
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true;
                // 计时秒数
                vm.count--;
                // 更新按钮的文字内容
                vm.content = vm.count + this.$t("login.later");
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false;
                // 更新按钮文字内容
                vm.content = this.$t("login.getSMSCode");
                // 清空定时器!!!
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.codeLoding = false;
          vm.content = this.$t("login.getSMSCode");
        });
    },
    // 微信公众号登录
    // wxlogin() {
    //   QRcode().then((res) => {
    //     this.qrUrl = res.data.QRUrl;
    //     const wxuuid = res.data.uuid;
    //     console.log(this.qrUrl);
    //     this.bindWxVisible = true;
    //     let that = this;
    //     let counter = 1;
    //     this.wxTimer && clearTimeout(this.wxTimer);
    //     this.wxTimer = setInterval(function () {
    //       loginwx(wxuuid)
    //         .then((res) => {
    //           console.log(res);
    //           counter++;
    //           console.log(counter);
    //           if (counter === 60) {
    //             clearTimeout(that.wxTimer);
    //             that.bindTimeout = true;
    //           }
    //           if (res.token != null) {
    //             setToken(res.token);
    //             that.bindWxVisible = false;
    //             that.$store.dispatch("uuidLogin", res);
    //             that.$message({
    //               type: "success",
    //               message: "登录成功",
    //             });
    //             setTimeout(() => {
    //               that.$router
    //                 .push({
    //                   path: that.redirect || "/",
    //                 })
    //                 .catch(() => {});
    //             }, 1500);
    //             clearInterval(that.wxTimer);
    //           }
    //         })
    //         .catch((err) => {
    //           // that.bindWxVisible = false;
    //           clearTimeout(that.wxTimer);
    //         });
    //     }, 1000);
    //   });
    //   var interval = setInterval(() => {
    //     loginwx(wxuuid)
    //       .then((res) => {})
    //       .catch((error) => {
    //         if (
    //           error.message === "用户已注销" ||
    //           error.message === "用户已删除"
    //         ) {
    //           clearInterval(interval);
    //           setTimeout(() => {
    //             location.reload();
    //           }, 5000);
    //         }
    //       });
    //   }, 5000);
    // },
    // 微信扫码登录
    wxlogin() {
      this.wxType = 1;
      this.wxQrLoading = true;
      getLoginUUid()
        .then((response) => {
          const uuid = response.data.uuid;
          console.log("uuid", uuid);
          const appId = process.env.VUE_APP_WX_APPID;
          console.log("appId", appId);
          const redirect_uri = `http://web-hub.uat.kai12.cn/auth.html?backUrl=${window.location.origin}/uuid/bind/openid?uuid=${uuid}`;
          const codeUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(
            redirect_uri
          )}&response_type=code&scope=snsapi_userinfo&state=123456#wechat_redirect`;
          this.qrUrl = codeUrl;
          console.log(this.qrUrl);
          let that = this;
          let counter = 1;
          this.wxTimer && clearTimeout(this.wxTimer);
          // this.wxTimer = setInterval(function () {
          //   uuidLogin({
          //     uuid: uuid,
          //   })
          //     .then((res) => {
          //       console.log(res);
          //       counter++;
          //       console.log(counter);
          //       if (counter === 60) {
          //         clearTimeout(that.wxTimer);
          //         that.bindTimeout = true;
          //       }
          //       if (res.status === 1) {
          //         clearTimeout(that.wxTimer);
          //         that.bindWxVisible = false;
          //         that.$message({
          //           type: "success",
          //           message: "登录成功",
          //         });
          //         clearTimeout(that.wxTimer);
          //         that.$store.dispatch("uuidLogin", res);
          //         setTimeout(() => {
          //           that.$router
          //             .push({
          //               path: that.redirect || "/",
          //             })
          //             .catch(() => {});
          //         }, 1500);
          //       }
          //     })
          //     .catch((err) => {
          //       // that.bindWxVisible = false;
          //       clearTimeout(that.wxTimer);
          //     });
          // }, 1000);
        })
        .finally(() => {
          this.wxQrLoading = false;
        });
    },
    turnBind() {
      this.wxType = 2;
      this.wxLoginClose();
    },
    backLogin() {
      this.wxType = 0;
    },
    wxLoginClose() {
      this.wxTimer && clearTimeout(this.wxTimer);
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // 切换密码显示状态
    },
    onImageLoad() {
      // 图片加载完成后，设置isOnLoad为true
      this.isOnLoad = true;
    },
    isMobile() {
      const userAgent = navigator.userAgent;
      // 判断是否包含手机浏览器的标志字符串
      return /iPhone|iPad|iPod|Android/i.test(userAgent);
    },
    redirectToMobileSite() {
      // 这里可以替换为你的跨域网址
      window.location.href = process.env.VUE_APP_PHONE_ADDRESS; // 替换为目标跨域地址
    },
    //快速注册
    // quickRegister() {
    //   createFreeAccount().then((res) => {
    //     this.$confirm(
    //       this.$t("quickSignUp.rememberCredentials") +
    //         this.$t("quickSignUp.accountLabel") +
    //         res.data.username +
    //         this.$t("quickSignUp.passwordLabel") +
    //         res.data.password,
    //       this.$t("quickSignUp.registrationSuccess"),
    //       {
    //         confirmButtonText: this.$t("quickSignUp.autoFill"),
    //         cancelButtonText: this.$t("cancel"),
    //         type: "success",
    //         dangerouslyUseHTMLString: true, // 启用 HTML 渲染
    //       }
    //     ).then(() => {
    //       this.loginForm.loginType = "username";
    //       this.loginForm.username = res.data.username;
    //       this.loginForm.password = res.data.password;
    //     });
    //   });
    // },

    // 发送手机验证码
    sendPhone() {
      if (this.loginForm.phone === "") {
        this.$refs.loginForm.validateField("phone");
        return;
      }
      if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.loginForm.phone)) {
        this.$refs.loginForm.validateField("phone");
        return;
      }
      if (this.loginForm.imgCode == null) {
        this.$refs.loginForm.validateField("imgCode");
        return;
      }
      let vm = this;
      vm.phoneContent = "";
      this.phoneLoading = true;
      // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
      sendMyPhone({
        phone: vm.loginForm.phone,
        uuid: vm.loginForm.uuid,
        code: vm.loginForm.imgCode,
      })
        .then((res) => {
          if (res.code === 200) {
            this.phoneLoading = false;
            vm.isDisabled = false;
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.phoneContent = vm.count + this.$t("login.later");
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true;
                // 计时秒数
                vm.count--;
                // 更新按钮的文字内容
                vm.phoneContent = vm.count + this.$t("login.later");
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false;
                // 更新按钮文字内容
                vm.phoneContent = this.$t("login.getSMSCode");
                // 清空定时器!!!
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.phoneLoading = false;
          vm.phoneContent = this.$t("login.getSMSCode");
          this.getCode();
        });
    },
    changeLoginType(type) {
      this.loginForm.loginType = type;
    },
    getCode() {
      getCodeImg().then((res) => {
        this.captchaEnabled =
          res.data.captchaEnabled === undefined
            ? true
            : res.data.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.data.img;
          this.loginForm.uuid = res.data.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rM = Cookies.get("rM");
      this.loginForm = {
        loginType: "username",
        username: username === undefined ? this.loginForm.username : username,
        password:
          password === undefined ? this.loginForm.password : decrypt(password),
        rM: rM === undefined ? false : Boolean(rM),
        agreed: true, // 默认勾选
      };
    },
    gotoRegister() {
      this.$router.push("/register");
    },
    gotoRorgot() {
      this.$router.push("/forgotPassword");
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rM) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), {
              expires: 30,
            });
            Cookies.set("rM", this.loginForm.rM, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove("rM");
          }
          let type;
          if (this.loginForm.loginType === "username") type = "Login";
          else if (this.loginForm.loginType === "email") type = "EmailLogin";
          else if (this.loginForm.loginType === "phone") type = "smsLogin";
          this.$store
            .dispatch(type, this.loginForm)
            .then(() => {
              this.$router.push({ path: this.redirect || "/" }).catch(() => {});
            })
            .catch(() => {
              this.loading = false;
              if (this.captchaEnabled) {
                this.getCode();
              }
            });
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.set-language {
  width: 20px;
  height: 20px;
  // margin: 16px 16px 5px;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 12px;
  right: 12px;
  color: white;
}
.right-wx {
  width: 69px;
  height: 69px;
  position: absolute;
  top: 0;
  right: 0;
}
.login-logo {
  height: 36px;
  width: 154px;
  margin: 41px 65px 44px;
}
.wx-logo {
  height: 100%;
  width: 100%;
}
.title-container {
  height: 48px;
  width: 168px;
  padding-top: 12px;
  .title {
    cursor: pointer;
    height: 24px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    line-height: 24px;
    text-align: left;
    font-style: normal;
  }
  .title-line {
    width: 60px;
    height: 2px;
    background: linear-gradient(90deg, #0090ff 0%, #a200ff 100%);
    border-radius: 1px;
    margin-top: 10px;
  }
}

.login-form {
  padding: 0 65px;

  ::v-deep .el-input--medium .el-input__inner {
    height: 46px;
    line-height: 46px;
    background: rgba(0, 119, 255, 0.06);
    border-radius: 14px;
    border: 1px solid #c3c3c3;
    color: rgba(0, 0, 0, 0.4);
  }
  ::v-deep .el-form-item {
    margin-bottom: 32px;
  }
  ::v-deep .el-input__suffix {
    right: 0px;
  }
  // /* 针对自动填充的输入框 */
  // ::v-deep input:-webkit-autofill {
  //   background-color: rgba(0, 119, 255, 0.06) !important; /* 保持原来的背景色 */
  //   border-color: #c3c3c3 !important; /* 保持原来的边框颜色 */
  //   color: rgba(0, 0, 0, 0.4) !important; /* 确保字体颜色不变 */
  // }
  // ::v-deep .el-input__inner:-webkit-autofill {
  //   background-color: rgba(0, 119, 255, 0.06) !important;
  //   border-color: #c3c3c3 !important;
  //   color: rgba(0, 0, 0, 0.4) !important;
  // }

  // /* Safari浏览器特有的自动填充样式 */
  // ::v-deep input:-webkit-autofill:focus {
  //   background-color: rgba(0, 119, 255, 0.06) !important;
  //   border-color: #c3c3c3 !important;
  //   color: rgba(0, 0, 0, 0.4) !important;
  // }
}

.login-code {
  width: 111px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-code-img {
    width: 111px;
    height: 46px;
    border-radius: 0 14px 14px 0;
    cursor: pointer;
    vertical-align: middle;
  }
}
.passwrod-eye {
  width: 52px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  .iconfont {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
}
.login-bottom {
  margin-top: 56px;
}
.wx-title {
  height: 34px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 30px;
  color: #000000;
  line-height: 34px;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin: 40px 65px 16px;
}
.wx-tip {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin: 0 65px 55px;
}
.qr-code {
  width: 318px;
  height: 318px;
  position: relative;
  .wx-code-over {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    cursor: pointer;
  }
}
.bind-btn-mt {
  margin-top: 100px;
}
@media screen and (max-height: 900px) {
  .set-language {
    width: 20px;
    height: 20px;
    margin: 16px 16px;
    cursor: pointer;
    z-index: 2;
  }
  .login-logo {
    height: 36px;
    width: 154px;
    margin: 41px 65px 44px;
  }
  .login-form {
    padding: 0 65px;

    ::v-deep .el-input--medium .el-input__inner {
      height: 46px;
      line-height: 46px;
      background: rgba(0, 119, 255, 0.06);
      border-radius: 14px;
      border: 1px solid #b9c4d5;
      color: rgba(0, 0, 0, 0.4);
    }
    ::v-deep .el-form-item {
      margin-bottom: 32px;
    }
    ::v-deep .el-input__suffix {
      right: 0px;
    }
  }

  .login-code {
    width: 72px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-code-img {
      width: 72px;
      height: 46px;
      border-radius: 0 14px 14px 0;
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .passwrod-eye {
    width: 52px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
  }
}
@media screen and (max-height: 768px) {
  .set-language {
    width: 20px;
    height: 20px;
    margin: 16px 16px;
    cursor: pointer;
    z-index: 2;
  }
  .login-logo {
    height: 26px;
    width: 111px;
    margin: 41px 35px 24px;
  }
  .login-form {
    padding: 0 26px;

    ::v-deep .el-input--medium .el-input__inner {
      height: 40px;
      line-height: 40px;
      background: rgba(0, 119, 255, 0.06);
      border-radius: 10px;
      border: 1px solid #b9c4d5;
      color: rgba(0, 0, 0, 0.4);
    }
    ::v-deep .el-form-item {
      margin-bottom: 32px;
    }
    ::v-deep .el-input__suffix {
      right: 0px;
    }
  }
  .login-code {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-code-img {
      width: 96px;
      height: 40px;
      border-radius: 0 10px 10px 0;
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .passwrod-eye {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
  }
  .login-bottom {
    margin-top: 20px;
  }
  .wx-title {
    height: 34px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    line-height: 34px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    margin-top: 32px;
    margin: 40px 26px 16px;
  }
  .wx-tip {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin: 0 26px 55px;
  }
  .bind-btn-mt {
    margin-top: 80px;
  }
}
</style>
