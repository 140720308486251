<template>
  <div class="msgHeader">
    <div class="msgHeaderTitle">
      {{ userName }}
    </div>
    <div class="refreshMsg" @click="refreshMsg">
      <i class="iconfont icon-a-danyuanpeizhi6"></i>
      {{ $t('messageCenter.refreshMessage') }}
    </div>
  </div>
</template>

<script>
import EventBus from '@/utils/EventBus'
export default {
  name: 'msgHeader',
  data() {
    return {
      userName: '',
      accountInfo: {}
    }
  },
  mounted() {
    EventBus.$on('proceed-chat', (info) => {
      this.userName = info.nickname || ''
      this.accountInfo = info
    })
  },
  methods: {
    refreshMsg() {
      if (this.accountInfo.conversationId) {
        this.$store.dispatch('chinMsg/getDetail', this.accountInfo)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.msgHeader {
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 17px;
  border-bottom: 1px solid #DCDCDC;
  justify-content: space-between;
  .msgHeaderTitle {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.9);
    line-height: 20px;
    text-align: left;
    font-style: normal;
  }
  .refreshMsg {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #016FEE;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    .icon-a-danyuanpeizhi6 {
      margin-right: 5px;
      font-size: 14px;
      color: #016FEE;
    }
  }
}
</style>
