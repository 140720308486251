<!-- 商品列表 -->
<template>
  <div class="app-container">
    <div class="leftActiveTab mb16">
      <div
        v-for="item in tabList"
        :key="item.label"
        @click="tabClick(item)"
      >
        <div :class="['tabItem', item.isActive && 'activeTab']"> {{ item.label }}</div>
      </div>
    </div>
    <div v-if="activeTab === 'tiktok'">
      <mall-tiktok></mall-tiktok>
    </div>
    <div v-if="activeTab === 'material'">
      <el-form
        :model="queryParams"
        ref="queryForm"
        size="small"
        :inline="true"
        v-show="showSearch"
        label-width="68px"
      >
        <el-form-item prop="businessType">
          <el-cascader
            :placeholder="$t('mall.businessDirection')"
            style="width: 100%;"
            :show-all-levels="false"
            :props="{value:'name',label:'name',emitPath: false}"
            v-model="queryParams.businessType"
            :options="industryCategoryOptions"
            @keyup.enter.native="handleQuery"
            clearable
          ></el-cascader>
        </el-form-item>

        <el-form-item>
          <div class="flex-row-center price-box">
            <el-input
              v-model="queryParams.priceMin"
              :placeholder="$t('mall.priceMin')"
              clearable
              @change="handleQuery"
              style="width: 100px;"
            />
            <span class="ml8 mr8">-</span>
            <el-input
              v-model="queryParams.priceMax"
              :placeholder="$t('mall.priceMax')"
              clearable
              @change="handleQuery"
              style="width: 100px;"
            />
          </div>
        </el-form-item>
        <el-form-item>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('refresh')"
            placement="top"
          >
            <i
              class="iconfont icon-a-danyuanpeizhi6"
              @click="getList"
            ></i>
          </el-tooltip>
        </el-form-item>
      </el-form>
      <el-empty
        v-if="mallGoodsList.length === 0"
        v-loading="loading"
        :image-size="200"
      ></el-empty>
      <div
        v-else
        class="flex-row f-wrap"
        v-loading="loading"
      >
        <material-item
          v-for="(item,index) in mallGoodsList"
          :key="item.id"
          :item="item"
          @toggle-selection="toggleSelectionSingle"
          style="margin: 0px 10px 10px 0px;"
          @add-cart-success="addCardSuccess(index)"
        ></material-item>
      </div>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import { industryCategoryTreeSelect } from "../../api/base/resource";
import MallTiktok from "./mallTiktok.vue";
import MaterialItem from "./MaterialItem.vue";
import { listMallGoods } from "@/api/mall/mallMaterial";

export default {
  // name: "Goods",
  components: { MallTiktok, MaterialItem },
  data() {
    return {
      tabList: [
        {
          label: this.$t("mall.tiktokZone"),
          isActive: true,
          name: "tiktok",
        },
        {
          label: this.$t("mall.materialZone"),
          isActive: false,
          name: "material",
        },
      ],
      activeTab: "tiktok",
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 商品表格数据
      mallGoodsList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        businessType: undefined,
        priceMin: undefined,
        priceMax: undefined,
        priceRange: [],
      },
      industryCategoryOptions: undefined, // 业务方向、行业分类
    };
  },
  created() {
    this.getList();
  },
  methods: {
    tabClick(item) {
      this.activeTab = item.name;
      this.tabList.forEach((tabItem) => {
        if (this.activeTab === tabItem.name) {
          tabItem.isActive = true;
        } else {
          tabItem.isActive = false;
        }
      });
    },
    /** 所属行业下拉树结构 */
    getIndustryCategoryOptionsTree() {
      industryCategoryTreeSelect().then((response) => {
        this.industryCategoryOptions = response.data;
      });
    },
    /** 查询商品列表 */
    getList() {
      if (
        !this.industryCategoryOptions ||
        this.industryCategoryOptions.length === 0
      ) {
        this.getIndustryCategoryOptionsTree();
      }
      this.loading = true;
      if (this.queryParams.priceMax) {
        this.queryParams.priceRange = [
          this.queryParams.priceMin || 0,
          this.queryParams.priceMax,
        ];
      } else {
        this.queryParams.priceRange = [this.queryParams.priceMin || 0];
      }
      listMallGoods(this.queryParams).then((response) => {
        this.mallGoodsList = response.rows;
        this.total = response.total;
        this.loading = false;
        this.mallGoodsList.forEach((item) => {
          item.selected = false;
        });
      });
    },
    //选中
    toggleSelectionSingle(selected, video) {
      console.log("selected", selected);
      // 取消所有其他项目的选择
      this.mallGoodsList = this.mallGoodsList.map((v) => ({
        ...v,
        selected: false,
      }));

      // 选择当前点击的项目
      const index = this.mallGoodsList.findIndex((v) => v.id === video.id);
      if (index !== -1) {
        this.$set(this.mallGoodsList, index, {
          ...this.mallGoodsList[index],
          selected,
        });
      }
      console.log("this.mallGoodsList", this.mallGoodsList);
      this.$router.push({ path: `/goodsDetail/${video.id}` });
    },
    addCardSuccess(index) {
      this.$set(this.mallGoodsList, index, {
        ...this.mallGoodsList[index],
        inCart: true,
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      (this.queryParams = {
        pageNum: 1,
        pageSize: 20,
        priceMin: undefined,
        priceMax: undefined,
        businessType: undefined,
        priceRange: [],
      }),
        this.resetForm("queryForm");
      this.handleQuery();
    },
  },
};
</script>
<style lang="scss" scoped>
.leftActiveTab {
  display: flex;
  .tabItem {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    text-align: center;
    font-style: normal;
    margin-right: 24px;
    cursor: pointer;
  }
  .activeTab {
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    background: linear-gradient(0deg, #0089ff 0%, #6e16d1 100%);
    background-clip: text; /* 无前缀标准写法 */
    -webkit-background-clip: text; /* 兼容 WebKit 浏览器 */
    -webkit-text-fill-color: transparent;
  }
}
::v-deep .el-input__inner {
  border: none;
  background: #f4f4f5;
  border-radius: 8px;
}
.price-box {
  border: none;
  background: #f4f4f5;
  border-radius: 8px;
}
</style>
