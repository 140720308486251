<template>
  <div class="msgContent">
    <div class="chatMessage">
      <div class="message-view-item" v-for="item in chatMsgList" :key="item.id" :class="[item.formMe ? 'formMe' : 'formOther']">
        <template v-if="!item.formMe">
          <div class="avatarImg">
            <el-avatar :size="56" :src="item.avatar"></el-avatar>
          </div>
          <div class="msgTextBox">
            <div class="flexTop">
              <div class="nameText">
                {{ item.userName }}
              </div>
              <div class="timeText">{{ item.time }}</div>
            </div>
            <div class="msgText">
              <div v-if="item.messageType === 'image'">
                <img class="imgMsg" :src="item.msgUrl" alt="">
              </div>
              <div>
                {{ item.message }}
              </div>
              <!-- <img :src="" alt=""> -->
            </div>
          </div>
        </template>
        <template v-if="item.formMe">
          <div class="msgTextBox">
            <div class="flexMsgState">
              <div class="leftState">
                <img v-if="item.msgState === 'send'" class="sendImg" :src="sendLoading" alt="">
                <div class="resendBox" v-if="item.msgState === 'error'" @click="resendMessage(item.message)">
                  <i class="iconfont icon-a-danyuanpeizhi6"></i>
                </div>
              </div>
              <!-- msgState -->
              <div>
                <div class="flexTop">
                  <div class="timeText">{{ item.time }}</div>
                  <div class="nameText">
                    {{ item.userName }}
                  </div>
                </div>
                <div class="msgText">
                  <div v-if="item.isFile">
                    <!-- fileType -->
                    <div v-if="item.fileType === 'image'">
                      <img class="imgMsg" :src="item.mediaUrls" alt="">
                    </div>
                    <div v-if="item.fileType === 'video'">
                      <video class="videoMsg" :src="item.mediaUrls"></video>
                    </div>
                    <div v-if="item.fileType === 'file'">
                      <i class="iconfont icon-wenanmoban"></i>
                      {{ item.fileName }}{{ item.fileSize }}
                    </div>
                  </div>
                  <div v-else>
                    {{ item.message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="avatarImg">
            <el-avatar :size="56" :src="item.avatar"></el-avatar>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import sendLoading from '@/assets/images/sendLoading.gif'
export default {
  name: 'msgContent',
  data() {
    return {
      sendLoading
      // chatMsgList: [
      //   {
      //     avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //     platform: 'youtube',
      //     userName: 'JACK akscbaskcaks',
      //     time: '2024-05-26 12:30',
      //     content: '太棒了！',
      //     formMe: false
      //   },
      //   {
      //     avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //     platform: 'youtube',
      //     userName: 'JACK akscbaskcaks',
      //     time: '2024-05-26 12:30',
      //     content: '太棒了！！',
      //     formMe: true
      //   },
      //   {
      //     avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //     platform: 'youtube',
      //     userName: 'JACK akscbaskcaks',
      //     time: '2024-05-26 12:30',
      //     content: '太棒了！！我觉得这个东西还蛮不错的，全网看了很多，还是你视频里的最符合我的预期！问有没有什么联系方式？急！',
      //     formMe: false
      //   },
      //   {
      //     avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //     platform: 'youtube',
      //     userName: 'JACK akscbaskcaks',
      //     time: '2024-05-26 12:30',
      //     content: '太棒了！！我觉得这个东西还蛮不错的，全网看了很多，还是你视频里的最符合我的预期！问有没有什么联系方式？急！',
      //     formMe: true
      //   },
      //   {
      //     avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //     platform: 'youtube',
      //     userName: 'JACK akscbaskcaks',
      //     time: '2024-05-26 12:30',
      //     content: '太棒了！！我觉得这个东西还蛮不错的，全网看了很多，还是你视频里的最符合我的预期！问有没有什么联系方式？急！',
      //     formMe: true
      //   },
      //   {
      //     avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //     platform: 'youtube',
      //     userName: 'JACK akscbaskcaks',
      //     time: '2024-05-26 12:30',
      //     content: '太棒了！！我觉得这个东西还蛮不错的，全网看了很多，还是你视频里的最符合我的预期！问有没有什么联系方式？急！',
      //     formMe: true
      //   },
      //   {
      //     avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //     platform: 'youtube',
      //     userName: 'JACK akscbaskcaks',
      //     time: '2024-05-26 12:30',
      //     content: '太棒了！！我觉得这个东西还蛮不错的，全网看了很多，还是你视频里的最符合我的预期！问有没有什么联系方式？急！',
      //     formMe: true
      //   },
      //   {
      //     avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //     platform: 'youtube',
      //     userName: 'JACK akscbaskcaks',
      //     time: '2024-05-26 12:30',
      //     content: '太棒了！！我觉得这个东西还蛮不错的，全网看了很多，还是你视频里的最符合我的预期！问有没有什么联系方式？急！',
      //     formMe: false
      //   },
      //   {
      //     avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      //     platform: 'youtube',
      //     userName: 'JACK akscbaskcaks',
      //     time: '2024-05-26 12:30',
      //     content: '太棒了！！我觉得这个东西还蛮不错的，全网看了很多，还是你视频里的最符合我的预期！问有没有什么联系方式？急！',
      //     formMe: true
      //   }
      // ]
    }
  },
  mounted() {
    // EventBus.$on('proceed-chat', (info) => {
    //   this.userName = info.userName || ''
    // })
  },
  computed: {
    ...mapState({
      chatMsgList: state => state.chinMsg.chatMsgList
    }),
  },
  methods: {
    resendMessage(message) {
      this.$store.dispatch('chinMsg/sendMessage', {message, isSend: false})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mixin.scss';
.msgContent {
  flex: 1;
  padding: 32px 16px;
  overflow: auto;
  @include chatScrollBar;
  .chatMessage {
    width: 100%;
    // margin: auto;
    display: flex;
    flex-direction: column;
    .message-view-item {
      display: flex;
      // margin: 8px 0;
      margin-bottom: 32px;
      position: relative;
      // width: 60%;
      max-width: 60%;
      .avatarImg {
        width: 56px;
        height: 56px;
      }
      .msgTextBox {
        .flexTop {
          display: flex;
          justify-content: space-between;
          .nameText {
            font-family: PingFang-SC, PingFang-SC;
            font-weight: bold;
            font-size: 12px;
            color: rgba(0,0,0,0.9);
            line-height: 17px;
            text-align: left;
            font-style: normal;
          }
          .timeText {
            font-family: PingFang-SC, PingFang-SC;
            font-weight: 400;
            font-size: 12px;
            color: rgba(0,0,0,0.4);
            line-height: 17px;
            text-align: left;
            font-style: normal;
          }
        }
        .msgText {
          border-radius: 10px;
          padding: 12px 8px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          margin-top: 5px;
          .imgMsg {
            width: 200px;
            height: 200px;
          }
          .videoMsg {
            width: 200px;
            height: 200px;
          }
        }
      }
    }
    .formOther {
      max-width: 60%;
      align-self: flex-start;
      justify-content: flex-start;
      .avatarImg {
        margin-right: 16px;
      }
      .msgText {
        background: #E7EEF5;
        color: #293951;
      }
    }
    .formMe {
      max-width: 60%;
      align-self: flex-end;
      justify-content: flex-end;
      .avatarImg {
        margin-left: 16px;
      }
      .msgText {
        background: #2787F5;
        color: #fff;
      }
      .flexMsgState {
        display: flex;
        align-items: center;
        .leftState {
          margin-right: 5px;
          margin-top: 20px;
          width: 30px;
          display: flex;
          align-items: center;
          .sendImg {
            width: 30px;
          }
          .resendBox {
            width: 18px;
            height: 18px;
            background: #E71010;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-a-danyuanpeizhi6 {
              color: #fff;
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
