<template>
  <div class="msgInput">
    <div class="sendInput">
      <div class="fileList" v-if="fileList.length">
        <div class="fileItem" v-for="item in fileList" :key="item.uid">
          <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom">
            <img v-if="isImage(item)" :src="item.previewUrl" alt="">
            <video v-else-if="isVideo(item)" :src="item.previewUrl"></video>
            <i v-else class="iconfont icon-wenanmoban"></i>
          </el-tooltip>
          <div class="closeBox" @click="closeFile(item)">x</div>
        </div>
      </div>
      <el-input
        id="postContent"
        style="width: 95%;"
        v-model="message"
        maxlength="200"
        :autosize="{ minRows: 2, maxRows: 3}"
        :placeholder="$t('messageCenter.placeholder')+$t('enter')"
        type="textarea"
        @keydown.native="handleKeyDown"
      ></el-input>
        <!-- @keyup.enter.native="sendMessage()" -->
        <div class="sendBtn">
          <i @click="showEmoPicker=!showEmoPicker" class="iconfont icon-a-danyuanpeizhi2"></i>
          <div class="sendMessage" @click="sendMessage">
            <i class="iconfont icon-telegram-normal"></i>
          </div>
        </div>
        <!-- <el-upload
          class="upload-demo"
          action=""
          accept=".jpg,.jpeg,.png,.gif,.mp4,.avi,.doc,.docx,.pdf,.xls,.xlsx"
          :on-exceed="handleExceed"
          :before-upload="handleBeforeUpload"
          multiple
          :limit="3"
          :file-list="fileList"
          >
            <i class="iconfont icon-a-xialajiantoubeifen22"></i>
        </el-upload> -->
        <msgEmopicker
          :inputId="'postContent'"
          class="emopiker"
          v-show="showEmoPicker"
          :showFrequentlyUsedList="false"
          @select="emojiClick"
          @selectBack="updateContent"
        />
    </div>
  </div>
</template>

<script>
import msgEmopicker from './msgEmopicker.vue';
import { getOssToken } from "@/api/ai/ali";
import OSS from "ali-oss";
export default {
  name: 'msgInput',
  components: {
    msgEmopicker
  },
  data() {
    return {
      message: '',
      showEmoPicker:false,
      fileList: [],
      client: null
    }
  },
  methods: {
    handleKeyDown(event) {
      // Shift + Enter 或 Option + Enter 换行
      if ((event.shiftKey || event.altKey || event.ctrlKey|| event.metaKey) && event.key === "Enter") {
        event.preventDefault();
        this.message += "\n";
        const textarea = event.target;
        this.$nextTick(() => {
          // 确保光标位置可见
          textarea.scrollTop = textarea.scrollHeight;
        });
        return
      }
      // 仅 Enter 发送消息
      else if (event.key === "Enter") {
        // 阻止默认换行行为
        event.preventDefault();
        this.sendMessage();
      }
    },
    closeFile(val) {
      this.fileList = this.fileList,filter(item => item.uid !== val.uid)
    },
    async sendMessage() {
      if (this.fileList.length) {
        this.client = await this.getOssClient();
        this.fileList.forEach(item => {
          this.uploadFile(item)
        })
        if (this.message.trim()) {
          this.$store.dispatch('chinMsg/sendMessage', {message: this.message, isSend: true, isFile: false})
        }
      } else {
        if (this.message.trim()) {
          this.$store.dispatch('chinMsg/sendMessage', {message: this.message, isSend: true, isFile: false})
        }
      }
      this.message = '';
    },
    async uploadFile(file) {
      console.log(file);
      try {
        const result = await this.client.put(`file/${this.getCurrentDate()}/${this.generateUniqueCode(30)}${file.name.replace(/^[^.]+/, '')}`, file);
        console.log(result, '上传至oss的文件');
        this.$store.dispatch('chinMsg/sendMessage', {
          mediaUrls: result.url,
          fileName: file.name,
          fileSize: file.size,
          fileType: this.isImage(file) ? 'image' : this.isVideo(file) ? 'video' : 'file',
          isSend: true,
          isFile: true
        })
      } catch (e) {
        console.log(e);
        this.loading = false
      }
    },
    async getOssClient() {
      // 临时凭证过期时重新获取，减少对STS服务的调用。
      if (this.isCredentialsExpired(this.credentials)) {
        this.credentials = await getOssToken();
      }
      return new OSS({
        bucket: process.env.VUE_APP_OSS_BUCKET, // OSS Bucket名称
        region: "oss-cn-beijing", // OSS所在地域
        accessKeyId: this.credentials.accessKeyId,
        accessKeySecret: this.credentials.accessKeySecret,
        stsToken: this.credentials.securityToken,
        timeout: 500000,
        secure: true
      });
    },
    isCredentialsExpired(credentials) {
      if (!credentials) {
        return true;
      }
      const expireDate = new Date(credentials.Expiration);
      const now = new Date();
      return expireDate.getTime() - now.getTime() <= 60000; // 有效期不足一分钟，视为过期
    },
    emojiClick(emoji){
      console.log(emoji)
      this.showEmoPicker = false
    },
    updateContent(resultText){
      console.log(resultText);
      this.message = resultText
    },
    handleBeforeUpload(file) {
      const isValidType = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/avi', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type);
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isValidType) {
        this.$message.error(this.$t('file.typeTip'));
      }
      if (!isLt100M) {
        this.$message.error(this.$t('file.sizeTip100'));
      }
      if (isValidType && isLt100M) {
        // 判断文件类型并生成预览链接
        if (this.isImage(file) || this.isVideo(file)) {
          file.previewUrl = URL.createObjectURL(file);
        } else {
          file.previewUrl = ''; // 其他类型文件预览链接为空
        }
        this.fileList.push(file); // 将文件对象添加到数组中
      }
    },
    isImage(file) {
      return file.type.startsWith('image/');
    },
    isVideo(file) {
      return file.type.startsWith('video/');
    },
    handleExceed(files, fileList) {
      this.$message.warning(this.$t('file.num3Tip1') + files.length + this.$t('file.num3Tip2') + (files.length + fileList.length) + this.$t('file.num3Tip3'));
    },
    generateRandomCode(length = 30) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    },
    generateUniqueCode(length) {
      const generatedCodes = new Set()
      let code
      do {
        code = this.generateRandomCode(length)
      } while (generatedCodes.has(code))
      generatedCodes.add(code)
      return code
    },
    getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}/${month}/${day}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.msgInput {
  // height: 64px;
  padding: 16px;
  // background: #DAE5F0;
  // border-radius: 32px;
  .sendInput {
    min-height: 90px;
    background: #DAE5F0;
    border-radius: 32px;
    // display: flex;
    // align-items: center;
    padding: 10px 50px 10px 30px;
    position: relative;
    .upload-demo {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      .el-upload-list {
        display: none;
      }
    }
    .fileList {
      height: 50px;
      padding: 0 0 5px 10px;
      display: flex;
      align-items: center;
      .fileItem {
        width: 50px;
        height: 50px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        .closeBox {
          width: 15px;
          height: 15px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #fff;
          background: #95AAC9;
          border-radius: 50%;
          position: absolute;
          top: -5px;
          right: -5px;
        }
        img {
          width: 50px;
          height: 50px;
        }
        video {
          width: 50px;
          height: 50px;
        }
        .icon-wenanmoban {
          font-size: 22px;
        }
      }
    }
    .emopiker {
      position: absolute;
      top: -155px;
      left: 10px;
    }
    .el-textarea__inner {
      border: none;
      height: 70px;
      background-color: transparent;
      caret-color: black;
      resize:none
    }
    .el-textarea__inner::-webkit-scrollbar {
      display: none;
    }
    .sendBtn {
      display: flex;
      align-items: center;
      position: absolute;
      right: 20px;
      bottom: 5px;
      .icon-a-danyuanpeizhi2 {
        font-size: 16px;
        margin-right: 14px;
        cursor: pointer;
      }
      .sendMessage {
        width: 44px;
        height: 44px;
        background: #2787F5;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon-telegram-normal {
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
