<template>
  <div class="app-container" v-loading="loading">
    <el-descriptions :column="3">
      <el-descriptions-item :label="$t('mall.orderNumber')">
        <span>{{ detail.orderNo }}</span>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('mall.orderTime')">
        <span>{{ goods.createTime }}</span>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('mall.payTime')">
        <span>{{ detail.payTime }}</span>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('mall.sellerInformation')">
        <span>{{ goods.sellNickName }}</span>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('mall.buyInformation')">
        <span>{{ goods.buyNickName }}</span>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('mall.orderStatus')">
        <dict-tag :options="dict.type.order_status" :value="detail.orderStatus"/>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('mall.paymentMethod')">
        <span>{{ $t('mall.wechat') }}</span>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="4" direction="vertical" border >
      <el-descriptions-item :label="$t('mall.productCode')">
        <span>{{ goods.id }}</span>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('mall.productInfo')">
        <div class="flex-row-center">
          <div class="flex-center" style="width: 80px;height: 80px;">
            <img :src="goods.coverUrl" style="max-width: 80px; max-height: 80px" />
          </div>
          <span class="ml5">{{ goods.title }}</span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item :label="`${$t('mall.unitPrice')} * ${$t('mall.quantity')}`">
        <span>￥{{ goods.price }} * {{ detail.count || 1 }}</span>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('mall.paidAmount')">
        <span>{{ detail.payPrice || detail.price * (detail.count || 1)}}</span>
      </el-descriptions-item>



    </el-descriptions>
  </div>
</template>
<script>
import { getMallOrder } from '../../api/mall/mallOrder';

export default {
  name: 'OrderDetail',
  components: {
  },
  dicts: [
    'order_status'
  ],
  data() {
    return {
      loading: false,
      id: '',
      detail: {},
      goods: {},
    }
  },
  created() {
    this.id = this.$route.params.id || '';
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      this.loading = true
      getMallOrder(this.id).then(res => {
        this.detail = res.data
        if (this.detail.mallGoodsSnapshotList && this.detail.mallGoodsSnapshotList.length > 0) {
          this.goods = this.detail.mallGoodsSnapshotList[0]
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
