var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "leftActiveTab mb16" },
      _vm._l(_vm.tabList, function (item) {
        return _c(
          "div",
          {
            key: item.label,
            on: {
              click: function ($event) {
                return _vm.tabClick(item)
              },
            },
          },
          [
            _c("div", { class: ["tabItem", item.isActive && "activeTab"] }, [
              _vm._v(" " + _vm._s(item.label)),
            ]),
          ]
        )
      }),
      0
    ),
    _vm.activeTab === "tiktok" ? _c("div", [_c("mall-tiktok")], 1) : _vm._e(),
    _vm.activeTab === "material"
      ? _c(
          "div",
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showSearch,
                    expression: "showSearch",
                  },
                ],
                ref: "queryForm",
                attrs: {
                  model: _vm.queryParams,
                  size: "small",
                  inline: true,
                  "label-width": "68px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "businessType" } },
                  [
                    _c("el-cascader", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("mall.businessDirection"),
                        "show-all-levels": false,
                        props: {
                          value: "name",
                          label: "name",
                          emitPath: false,
                        },
                        options: _vm.industryCategoryOptions,
                        clearable: "",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleQuery($event)
                        },
                      },
                      model: {
                        value: _vm.queryParams.businessType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "businessType", $$v)
                        },
                        expression: "queryParams.businessType",
                      },
                    }),
                  ],
                  1
                ),
                _c("el-form-item", [
                  _c(
                    "div",
                    { staticClass: "flex-row-center price-box" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        attrs: {
                          placeholder: _vm.$t("mall.priceMin"),
                          clearable: "",
                        },
                        on: { change: _vm.handleQuery },
                        model: {
                          value: _vm.queryParams.priceMin,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "priceMin", $$v)
                          },
                          expression: "queryParams.priceMin",
                        },
                      }),
                      _c("span", { staticClass: "ml8 mr8" }, [_vm._v("-")]),
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        attrs: {
                          placeholder: _vm.$t("mall.priceMax"),
                          clearable: "",
                        },
                        on: { change: _vm.handleQuery },
                        model: {
                          value: _vm.queryParams.priceMax,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "priceMax", $$v)
                          },
                          expression: "queryParams.priceMax",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("refresh"),
                          placement: "top",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-a-danyuanpeizhi6",
                          on: { click: _vm.getList },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.mallGoodsList.length === 0
              ? _c("el-empty", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { "image-size": 200 },
                })
              : _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "flex-row f-wrap",
                  },
                  _vm._l(_vm.mallGoodsList, function (item, index) {
                    return _c("material-item", {
                      key: item.id,
                      staticStyle: { margin: "0px 10px 10px 0px" },
                      attrs: { item: item },
                      on: {
                        "toggle-selection": _vm.toggleSelectionSingle,
                        "add-cart-success": function ($event) {
                          return _vm.addCardSuccess(index)
                        },
                      },
                    })
                  }),
                  1
                ),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total>0",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.queryParams.pageNum,
                limit: _vm.queryParams.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.queryParams, "pageNum", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.queryParams, "pageSize", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }