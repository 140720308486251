var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "flex-row-center f-end w-100 mb10" }, [
        _c(
          "div",
          { staticClass: "flex-row-center" },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.$t("refresh"),
                  placement: "top",
                },
              },
              [
                _c("i", {
                  staticClass: "iconfont icon-a-danyuanpeizhi6",
                  on: { click: _vm.getList },
                }),
              ]
            ),
            _c(
              "div",
              { staticStyle: { width: "400px", margin: "0 5px" } },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "account-input",
                    attrs: {
                      placeholder: _vm.$t("mall.enterOrderNumber"),
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "orderNo", $$v)
                      },
                      expression: "queryParams.orderNo",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-input__icon iconfont icon-sousuotubiao",
                      attrs: { slot: "prefix" },
                      on: { click: _vm.handleQuery },
                      slot: "prefix",
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "header-row-class-name": "custom_table_header",
            height: "calc(100vh - 270px)",
            data: _vm.mallOrderList,
          },
          on: {
            "filter-change": _vm.filterChange,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-empty", {
            attrs: { slot: "empty", "image-size": 180 },
            slot: "empty",
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.orderNumber"),
              align: "center",
              prop: "orderNo",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.mallOrder.orderNo))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.productInfo"),
              align: "center",
              prop: "title",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex-row-center" }, [
                      _c(
                        "div",
                        {
                          staticClass: "flex-center",
                          staticStyle: { width: "50px", height: "50px" },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "50px",
                              "max-height": "50px",
                            },
                            attrs: { src: scope.row.coverUrl },
                          }),
                        ]
                      ),
                      _c("span", { staticClass: "ml5" }, [
                        _vm._v(_vm._s(scope.row.title)),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.buyInformation"),
              align: "center",
              prop: "buyNickName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.price"),
              align: "center",
              prop: "price",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.quantity"),
              align: "center",
              prop: "count",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.mallOrder.count || 1)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("mall.orderTime"),
              align: "center",
              prop: "createTime",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.mallOrder.createTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "column-key": "orderStatus",
              prop: "orderStatus",
              align: "center",
              label: _vm.$t("mall.orderStatus"),
              filters: _vm.orderStatusFilters,
              "filter-multiple": false,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("dict-tag", {
                      attrs: {
                        options: _vm.dict.type.order_status,
                        value: scope.row.mallOrder.orderStatus,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operate"),
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "iconfont icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("detail")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }