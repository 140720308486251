var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "msgContent" }, [
    _c(
      "div",
      { staticClass: "chatMessage" },
      _vm._l(_vm.chatMsgList, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "message-view-item",
            class: [item.formMe ? "formMe" : "formOther"],
          },
          [
            !item.formMe
              ? [
                  _c(
                    "div",
                    { staticClass: "avatarImg" },
                    [
                      _c("el-avatar", {
                        attrs: { size: 56, src: item.avatar },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "msgTextBox" }, [
                    _c("div", { staticClass: "flexTop" }, [
                      _c("div", { staticClass: "nameText" }, [
                        _vm._v(" " + _vm._s(item.userName) + " "),
                      ]),
                      _c("div", { staticClass: "timeText" }, [
                        _vm._v(_vm._s(item.time)),
                      ]),
                    ]),
                    _c("div", { staticClass: "msgText" }, [
                      item.messageType === "image"
                        ? _c("div", [
                            _c("img", {
                              staticClass: "imgMsg",
                              attrs: { src: item.msgUrl, alt: "" },
                            }),
                          ])
                        : _vm._e(),
                      _c("div", [_vm._v(" " + _vm._s(item.message) + " ")]),
                    ]),
                  ]),
                ]
              : _vm._e(),
            item.formMe
              ? [
                  _c("div", { staticClass: "msgTextBox" }, [
                    _c("div", { staticClass: "flexMsgState" }, [
                      _c("div", { staticClass: "leftState" }, [
                        item.msgState === "send"
                          ? _c("img", {
                              staticClass: "sendImg",
                              attrs: { src: _vm.sendLoading, alt: "" },
                            })
                          : _vm._e(),
                        item.msgState === "error"
                          ? _c(
                              "div",
                              {
                                staticClass: "resendBox",
                                on: {
                                  click: function ($event) {
                                    return _vm.resendMessage(item.message)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-a-danyuanpeizhi6",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "flexTop" }, [
                          _c("div", { staticClass: "timeText" }, [
                            _vm._v(_vm._s(item.time)),
                          ]),
                          _c("div", { staticClass: "nameText" }, [
                            _vm._v(" " + _vm._s(item.userName) + " "),
                          ]),
                        ]),
                        _c("div", { staticClass: "msgText" }, [
                          item.isFile
                            ? _c("div", [
                                item.fileType === "image"
                                  ? _c("div", [
                                      _c("img", {
                                        staticClass: "imgMsg",
                                        attrs: { src: item.mediaUrls, alt: "" },
                                      }),
                                    ])
                                  : _vm._e(),
                                item.fileType === "video"
                                  ? _c("div", [
                                      _c("video", {
                                        staticClass: "videoMsg",
                                        attrs: { src: item.mediaUrls },
                                      }),
                                    ])
                                  : _vm._e(),
                                item.fileType === "file"
                                  ? _c("div", [
                                      _c("i", {
                                        staticClass: "iconfont icon-wenanmoban",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(item.fileName) +
                                          _vm._s(item.fileSize) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _c("div", [
                                _vm._v(" " + _vm._s(item.message) + " "),
                              ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "avatarImg" },
                    [
                      _c("el-avatar", {
                        attrs: { size: 56, src: item.avatar },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }