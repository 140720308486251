<template>
  <div class="flex-column video-item" :class="{ 'selected': item.selected }" @click.stop="toggleSelection">
    <div class="flex-center item-image">
      <!-- <svg-icon icon-class="mall-tiktok" style="width: 130px;height: 130px;"/> -->
      <!-- @error="handleImageError" -->
      <img :src="getCover(item.coverUrl)" alt="image" class="image-cover" />
    </div>
    <div>
      <div class="video-name">{{ item.title }}</div>
      <div class="video-description single-line-ellipsis" v-if="item.description">{{ item.description }}</div>

    </div>
    <div class="flex-row-center f-between">
      <!-- <div>
        <div class="flex-row-center">
          <i
            class="iconfont mr5 font20"
            :class="['icon-' + item.platform  + '-normal', item.platform]"
          ></i>
          <dict-tag :options="dict.type.social_platform" :value="item.platform" />
        </div>
      </div> -->
      <div class="single-line-ellipsis f-grow" style="color: blue;font-size: 12px;">
        {{ item.businessType }}
      </div>
      <span class="f-shrink">{{ $t('mall.residual') }}：{{item.quantity}}</span>
    </div>
    <!-- <div class="flex-row-center f-between"> -->
      <!-- <div>¥{{ item.price }}</div>
      <div class="strikethrough">¥{{ item.originalPrice }}</div> -->
      <!-- <el-tooltip :content="item.inCart ? $t('mall.addedToCart') : $t('mall.addToCart')">
        <el-button :loading="loading" icon="el-icon-shopping-cart-full" :disabled="item.inCart" circle @click.stop="addCart"></el-button>
      </el-tooltip> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { addMallCart } from '../../api/mall/mallCartItems';
export default {
  name: 'TiktokItem',
  dicts: [
    'social_platform'
  ],
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      // selected: false,  // 添加选中状态
      loading: false,
    };
  },
  methods: {
    //获取封面——默认第一张图片
    getCover(url){
      if(url){
        let arr = url.split(',')
        return arr[0]
      }else{
        return ''
      }
    },
    handleImageError(event) {
      // 使用 require 指定本地 SVG 图片路径
      event.target.src = require('@/assets/icons/svg/mall-tiktok.svg').default;
    },
    //加入购物车
    addCart(){
      this.loading = true
      addMallCart({
        mallGoodsId:this.item.id,
        count:1
      }).then(res=>{
        this.$modal.msgSuccess(this.$t('mall.addToCartSuccess'));
        this.$emit('add-cart-success', this.item);
      }).finally(()=>{
        this.loading = false
      })
    },
    toggleSelection() {
      this.item.selected = !this.item.selected;
      console.log('this.item.selected',this.item.selected);
      this.$emit('toggle-selection', this.item.selected, this.item);
    }
  }
};
</script>

<style scoped>
.video-item {
  width: 200px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.video-item.selected {
  border-color: blue; /* 选中时的样式示例 */
}
.item-image{
  width: 190px;
  height: 190px;
  /* background-color: black;
  border-radius: 5px; */
}
.image-cover {
  max-width: 190px; /* 限制最大宽度 */
  max-height: 190px; /* 限制最大高度 */
  width: auto; /* 自动调整宽度 */
  height: auto; /* 自动调整高度 */
}
.video-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.video-description{
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.strikethrough{
  text-decoration: line-through; /* 添加划线效果 */
  color: #999;                   /* 可选：调整颜色以区分原价 */
}
</style>
